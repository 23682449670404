//console.log("oha_timer definition")
(function ($) {
    $.fn.oha_timer=function(_opt) {
        var self=this;
        var _default={
            maxTime:-1,     // in the unit of "sec", -1 indicates no-max time
            minTime:1,  // in the unit of "sec"        
            setTime:3600,
            stepTime:60,
            microAdjustMode:false,      // Adjust inc/dec timer with step 15 sec when less than 1 min remain time
            microModeStepTime: 15, 
        }
        $.extend(_default, _opt);
        var startT, curT, remainSec, lastSetTime;
        var timerH;     // Timer handler
        var timeSet=_default.setTime;
		var isPlaying=false;
        this.max=_default.maxTime;
        this.min=_default.minTime;
        lastSetTime=timeSet;
        this.timeup=function() {
            console.log('this.timeup')
    //        timeup();
        }

        if(_default.hasOwnProperty("timeup")) {
//            console.log("timeup is updated");
            this.timeup=_default.timeup;
        }
//        this.each(function() {
////            console.log("hello, oha_timer");
//        });
//       if(typeof _t!=="undefined") {
//            var _tx=parseFloat(_t);
//            if(_tx > 0) {
//                this.setTime(_tx);
//            }
//        }
        if(typeof _max!=="undefined") {
            var max=parseInt(_max);
            if(max >0) {
                this.max=max;
            }
        }
    //    $(init);

        this.setTime=function(_t) {
			console.log("oha_timer.setTime, _t="+_t);
			if(typeof(_t)!="number") {
				_t=parseInt(_t);
			}
			if(_t>self.max*60) {
				_t=self.max*60;
			}
            timeSet=_t;
            lastSetTime=_t;
            remainSec=_t;
            startT=new Date().getTime();
            $(this).text(formatTime(timeSet));
        };
        
        this.setRemainT=function(_t) {
			if(typeof(_t)!="number") {
				_t=parseInt(_t);
			}
			if(_t>self.max*60) {
				_t=self.max*60;
			}
            remainSec=_t;
			lastSetTime=_t;
//            startT=new Date().getTime()-(timeSet-remainSec)*1000;
            $(this).text(formatTime(remainSec));
			console.log("oha_timer.setRemainTime, _t="+_t+", lastSetTime="+lastSetTime);
        };
        
        this.setMax=function(_max) {
            if(typeof _max == "undefined") {
                console.log("oha_timer.setMax has no set time");
                return;
            }
            var max=parseInt(_max);
            if(max >0) {
                this.max=max;
            }
        }

         this.setMin=function(_min) {
            if(typeof _min == "undefined") {
                console.log("oha_timer.setMin has no set time");
                return;
            }
            var min=parseInt(_min);
            if(min >0) {
                this.min=min;
            }
        }

		this.isTestingMode=false;
		 var testCntr=0;
		var testModeH=null;
		var testMax=-1;
       this.incTime=function(_ts) {
            var t=_default.stepTime;    // Default = 60sec
            if(typeof _ts!=="undefined") {
                t=_ts;
            }
            if(this.max<0 || (this.max>=Math.floor((timeSet+t)/60))) {
                timeSet+=t;
                lastSetTime+=t;
                remainSec+=t;
                show(remainSec);
            }
            else {
                if(typeof oha_api !=="undefined") {
                    if(this.max>0) {
						if(testCntr>=2 && testCntr<5) {
							oha_api.showToast((5-testCntr)+" more clicks for timer testing mode!!");
						}
						else {
							oha_api.showToast("Timer max. value arrives");
						}
						self.setTime(self.max*60);
						if(!self.isTestingMode && !isPlaying && t==60) {
//							console.log("incTime cp1, isTestingMode=false")
							if(testModeH!=null) {
								clearTimeout(testModeH);
								testModeH=null;
							}
							testModeH=setTimeout(function(){
								testCntr=0;
								testModeH=null;
//								console.log("testModeH timeout, testCntr=0")
							}, 5000);
							if(testCntr==5) {
								self.isTestingMode=true;
								testCntr=0;
								testMax=this.max;
								this.max=-1;
								$(self).css({"color":"red"})
								console.log("Testing mode is enable!!")
								oha_api.showToast("Testing mode is enable!!");
							}
							else {
								testCntr++;
							}
						}
                    }
                }
            }
           return timeSet;
        }
	   
	   $(self).click(function(){
		   if(self.isTestingMode && !isPlaying) {
				if(testModeH!=null) {
					clearTimeout(testModeH);
					testModeH=null;
				}
				if(testCntr==4) {
					self.isTestingMode=false;
					self.max=testMax;
					self.setTime(self.max*60);
					$(self).css({"color":""})
					oha_api.showToast("Testing mode is disable!!");
				}
				else {
					testCntr++;
					oha_api.showToast((5-testCntr)+" more clicks for disable testing mode!!");
				}
				testModeH=setTimeout(function(){
					testCntr=0;
					testModeH=null;
				}, 5000);

		   }
	   })

        this.decTime=function(_ts) {
            console.log('进到了time');
            var t=_default.stepTime;    // Default = 60sec
            if(typeof _ts!=="undefined") {
                t=parseInt(_ts);
            }
            if(remainSec-t>=this.min) {
                timeSet-=t;
                lastSetTime-=t;
                remainSec-=t;
                show(remainSec);
           }
            else {
                if(typeof oha_api !=="undefined") {
                    oha_api.showToast("Invalid operation!!");
                }
            }
            return timeSet;
        }
		
		this.getSetTime=function() {
            console.log(timeSet);
			return timeSet;
		}

        this.getRemainTime=function() {
            return remainSec;
        }
        
        this.play=function() {
			clearTimeout(timerH);
//			if(isPlaying) {
//				clearTimeout(timerH);
//			}
            startT=new Date().getTime();
//			if(timerH!=null) {
//				clearInterval(timerH);
//				timerH=null;
//			}
			isPlaying=true;
            timerH=setTimeout(function() {
                checkTime()
            }, 250);
//            timerH=setInterval(function() {
//                checkTime()
//            }, 250);
        }

        this.pause=function(){
			isPlaying=false;
//            clearInterval(timerH);
            clearTimeout(timerH);
            lastSetTime=remainSec;
        }

        this.stop=function(){
			isPlaying=false;
            stop();
        }

		this.sync=function() {
			clearTimeout(timerH);
			checkTime();
		}

        function init () {
//            console.log("init(), timeSet="+timeSet+","+formatTime(timeSet));
            remainSec=_default.setTime;
            $(self).text(formatTime(timeSet));
//            $(window).ready(function() {
//                $(self).text(formatTime(timeSet));
//            })
    //        $(timerContainer).text(formatTime(timeSet));
        }

        stop=function() {
//            clearInterval(timerH);
			isPlaying=false;
            clearTimeout(timerH);
			lastSetTime=timeSet;
			remainSec=timeSet;
			// $(self).text(formatTime(timeSet));
//			setTimeout(function(){
//				lastSetTime=timeSet;
//				remainSec=timeSet;
//				$(self).text(formatTime(timeSet));
//			}, 250)
        }

        function checkTime() {
			if(!isPlaying) {
				return;
			}
            curT=new Date().getTime();
            var _pastSec=Math.floor((curT-startT)/1000);
            var _remain=lastSetTime-_pastSec;
//            console.log('hello, checkTime, lastSetTime='+lastSetTime+', _remain='+_remain+", remainSec="+remainSec)
            if(_remain !==remainSec) {
                remainSec=_remain;
    //            if(remainSec<0) {
    //                remainSec=0;
    //            }
    //            $(timerContainer).text(formatTime(remainSec));
                show(remainSec);
            }
			clearTimeout(timerH);
           if(remainSec<=0) {
                console.log("oha_timer stop")
                stop();
                self.timeup();
            }
			else {
				timerH=setTimeout(function(){
					checkTime();
				}, 250);
			}
        }

    //    function timeup() {
    //        console.log('timeup')
    //        this.timeup();
    //    }
        function show(_t) {
            $(self).text(formatTime(_t));
        }

        function formatTime(_sec) {
            var min = Math.floor(_sec / 60);
            sec = _sec - (min * 60);
            /*hundredths = pad(time - (sec * 100) - (min * 6000), 2)*/;
            return (min > 0 ? pad(min, 2) : "00") + ":" + pad(sec, 2);
        }
    //    $(window).load(function() {
    //        this.init();
    //    })

        function pad(number, length) {
            var str = '' + number;
            while (str.length < length) {str = '0' + str;}
            return str;
        }

        init();
        return this;
    }
})($);
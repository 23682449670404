
import oha_api from './oha_api'
// JavaScript Document
var oha_config={
	debug_mode:false,			// Debug mode, must be set as "false" before release
	sw_ver:new Date().valueOf(), 
	model:"&oMass",				// Model Name, must be the same as Config.html
	page_name:"oController3.0",			// Page Name, different page must be different page name
    default_language:oha_api.langCode.English, 
    isCheckModelName: false,        
    isStopPolling:false,
    configPath:'https://www.allslug.com/config.js',
  
}

// ================== Definition for UI page by language setting =====================
oha_config.html_config=new Object();
oha_config.html_config[oha_api.langCode.English]="default_us.html";
oha_config.html_config[oha_api.isoLangs.Chinese]="default_tw.html";
oha_config.html_config[oha_api.langCode.SimpleChinese]="default_cn.html";
oha_config.html_config[oha_api.langCode.Korean]="default_kr.html";
// ================== Definition for Title/Subtitle=====================
//English
oha_config.title=new Object();
oha_config.subtitle=new Object();

//oha_config.title[oha_api.langCode.English]="oTHE Demo(v"+oha_config.sw_ver+")";
//oha_config.subtitle[oha_api.langCode.English]="oTHE OHA API Demo";
oha_config.title[oha_api.isoCountries.English]="OHA Controller for oMass";
oha_config.subtitle[oha_api.isoCountries.English]="oMass 3.0(Integrated Massage & Professional mode)";
// TraditionalChinese
oha_config.title[oha_api.isoLangs.Chinese]="OHA Controller for oMass";
oha_config.subtitle[oha_api.isoLangs.Chinese]="oMass 3.0(Integrated Massage & Professional mode)";
// SimleChinese
oha_config.title[oha_api.langCode.SimpleChinese]="OHA Controller for oMass";
oha_config.subtitle[oha_api.langCode.SimpleChinese]="oMass 3.0(Integrated Massage & Professional mode)";
// Korea
oha_config.title[oha_api.langCode.Korean]="OHA Controller for oMass";
oha_config.subtitle[oha_api.langCode.Korean]="oMass 3.0(Integrated Massage & Professional mode)";

//oha_config.title[oha_api.isoCountries.Japan]="oMass";
//oha_config.subtitle[oha_api.isoCountries.Japan]="Healthy, Convient and Funny";
// =========================================================================

oha_api.init(oha_config);           // Initial the oha_api with customized config object
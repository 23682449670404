console.log("window.ready read local oha_controller_options.txt OK");
import oha_api from '../../utils/oha_api'
console.log(5555555555);
console.log(oha_api);
import $ from 'jquery';
// JavaScript Document
    var forDebug=true;
	var ohaVer = 0;
	var isDevReady=false;
//var isReadOnly=false;
var _isSoundOn=true;
var _isPowerMode=true;
var isStarted=false;
var isMax33Hz=false;
var isMax60V=false;
var isAD983x=false;
var winHeight=0;
var winWidth=0;
var oha_timer; 
var timerBtnPressStart=-1;
var AD983xObj={
	baseF: 25000000, 
	ffactor: Math.pow(2,28)/25000000, 
}


const modelTypeDef={
	Normal: 0, 
	Face: 1
};
var modelType=modelTypeDef.Normal;
var classDef={
    alarmMode:"alarmOn",
    powerMode:"powerMode",
}
var JsonKey={
    ui:{
        key:"OHA_UI",
        property:{
            title:"title", 
        }
     }
}
var oMassJsonKey={
    patName: "Name", 
    patNo: "PatternNo", 
    patMode: "PatternMode", 
    voltageEn: "VoltogeEnable",
    freqEn: "FreqencyEnable",
    widthEn: "WidthEnable",   
    voltageDefault: "default_Volotage",
    freqDefault: "default_Freqency",
    widthDefault: "default_Pulse_Width",
	maxVoltage: "MaxVolatage", 
	maxFreq: "MaxFrequency", 
	maxWidth: "MaxWidth", 
    pattern: "Pattern",
    squenceMode: "Sequence_Mode", 
    loop: "PatternLoop",
    defaultV: "Default_Voltage", 
    sequence: "Sequence", 
    patternCmd: "PatternCmd",
	patDefault: "Pattern_Default"
}
var MsgString={
	TitleMenu: {
		"en-US":"Settings", 
		"zh":"設定", 
		"zh-CN":"设定",
		"default": "Settings"
	}, 
	BackMenu: {
		"en-US":"BACK", 
		"zh":"回原畫面", 
		"zh-CN":"回原画面",
		"default": "BACK"
	}, 
	SoundMenu: {
		On: {
			"en-US": "Alert sound on", 
			"zh": "聲音提醒模式開啟", 
			"zh-CN": "声音提醒开启", 
			"default": "Alert sound on"
		}, 
		Off: {
			"en-US": "Alert sound off", 
			"zh": "聲音提醒模式關閉", 
			"zh-CN": "声音提醒模式关闭", 
			"default": "Alert sound off"
		}
	}, 
	PowerSaveMenu: {
		On: {
			"en-US":"Power saving mode on", 
			"zh":"省電模式開啟", 
			"zh-CN":"省电模式开启", 
			"default": "Power saving mode on"
		}, 
		Off: {
			"en-US":"Power saving mode off", 
			"zh":"省電模式關閉", 
			"zh-CN":"省电模式关闭",
			"default": "Power saving mode off"
		}
	}, 
	PowerSetMenu: {
		p90: {
			"en-US":"Power saving rate is 90%", 
			"zh":"省電率90%", 
			"zh-CN":"省电率90%",
			"default": "Power saving rate is 90%"
		}, 
		p50: {
			"en-US": "Power saving rate is 50%", 
			"zh": "省電率50%", 
			"zh-CN": "省电率50%",
			"default": "Power saving rate is 50%"
		}
	}, 
	UiStyleMenu: {
		"en-US":"Professional style", 
		"zh":"專業版UI", 
		"zh-CN":"专业版UI",
		"default": "Professional style"
	}, 
	UiSizeMenu: {
		large: {
			"en-US":"Enlarged UI Size", 
			"zh":"人機界面放大顯示", 
			"zh-CN":"人机界面放大显示",
			"default": "Enlarged UI Size"
		}, 
		normal: {
			"en-US":"Normal UI Size", 
			"zh":"人機界面正常大小", 
			"zh-CN":"人机界面正常大小",
			"default": "Normal UI Size"
		}
	}, 
	StrCookieMenu: {
		off: {
			"en-US":"Voltage setting by default", 
			"zh":"電壓設定不予保存", 
			"zh-CN":"电压设定不予保存",
			"default": "Voltage setting by default"
		}, 
		on: {
			"en-US":"Keep the voltage setting", 
			"zh":"保存電壓設定", 
			"zh-CN":"保存电压设定",
			"default": "Keep the voltage setting"
		}
	}, 
	IosPatMenu: {
		ios: {
			"en-US":"iOS specific pattern", 
			"zh":"iOS專屬輸出設定", 
			"zh-CN":"iOS专属输出设定",
			"default":"iOS specific pattern"
		}, 
		normal: {
			"en-US":"Normal pattern", 
			"zh":"一般輸出設定", 
			"zh-CN":"一般输出设定",
			"default":"Normal pattern"
		}
	}, 
	UiVerMenu: {
		"en-US":"UI version: ", 
		"zh":"版本: ", 
		"zh-CN":"版本: ",
		"default": "UI version: "
	}
}
var strMapTab=[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380, 390, 400];
//var strParamTab=[1,3,8,15,22,30,40,51,64,85];
var strParamTab=[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];
var freqMapTab=[0.5,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
var freqParamTab=[2000,1000,500,333,250,200,167,143,125,111,100,91,83,77,71,67,63,59,56,53,50,48,45,43,42,40,38,37,36,34,33];
var p=32;
for(var i=32;i>=10;i--) {
    freqMapTab[63-i]=Math.round(1000/p);
    freqParamTab[63-i]=p--;
}
var widthMapTab=[25, 50, 100, 150, 200, 300, 400, 500, 600, 800];
var widthParamTab=[1,2,4,6,8,12,16,20,24,32];

var oMassConfig=[];
var oMassItemConfig=[];
for(var i=0;i<8;i++) {
    var _config=new Object();
    _config[oMassJsonKey.patNo]=i;
    _config[oMassJsonKey.patMode]=true;
    _config[oMassJsonKey.squenceMode]=0;
    switch(i) {
        case 0:
           //Shoulder/鑲� ::
          // 
          _config[oMassJsonKey.patName]={
              default: "Shoulder/鑲�",
          };
              _config[oMassJsonKey.patternCmd]='omass://pattern_254_100,16,+10,Cross,3.5,0;100,16,+0,Cross,3.5,0;200,16,+20,Cross,3.5,0;200,16,+10,Cross,3.5,0;200,16,+0,Cross,3.5,0;200,16,+10,Cross,3.5,0;200,16,+20,Cross,3.5,0;200,16,+10,Cross,3.5,0;100,16,+10,Cross,3.5,0; 100,16,+20,Cross,3.5,0;10,16,+0,Cross,1,0;20,16,+5,Cross,1,0;10,16,+0,Cross,1,0;20,16,+5,Cross,1,0';
  
        break;
        case 1:
           //Waist 鑵� :
          _config[oMassJsonKey.patName]={
              default: "Waist 鑵�",
          };
          _config[oMassJsonKey.patternCmd]='omass://pattern_254_6,16,+0,x,5.6,0.542;6,16,+2,x,4.68,1.065;9,16,+2,x,6.24,0.5;14,16,+6,x,4.65,1;20,16,+8,x,6.225,0.575;24,16,+10,x,4.85,1.084;29,16,+12,x,3.92,0.569;9,16,+2,x,5.1,1.089;20,16,+8,x,4.136,0.554;29,16,+12,x,4.98,1.061';
  
        break;
        case 2:
           //Elbow 鑲�/鑶�:
          _config[oMassJsonKey.patName]={
              default: "Elbow 鑲�/鑶�",
          };
          _config[oMassJsonKey.patternCmd]='omass://pattern_254_14,16,+0,x,0.25,1.32;20,16,+5,x,0.236,0.763;24,16,+10,x,0.2,0.365;24,16,+10,x,0.261,0.4;29,16,+10,x,0.2,0.711;29,16,+10,x,0.229,0.7;24,16,+10,x,0.2,0.365;24,16,+10,x,0.261,1.3;20,16,+5,x,0.236,1.3'; 
  
        break;
        case 3:
            //feet/瓒�
          _config[oMassJsonKey.patName]={
              default: "feet/瓒�",
          };
          _config[oMassJsonKey.patternCmd]='omass://pattern_254_6,16,+0,x,5,1;6,16,-5,x,5,1;14,16,+10,x,4,0.5;14,16,+5,x,4,0.5';
          break;
        case 4:
              //press 鎸�/澹�
          _config[oMassJsonKey.patName]={
              default: "press 鎸�/澹�",
          };
          _config[oMassJsonKey.patternCmd]='omass://pattern_254_30,16,+0,x,4.5,1.5s;50,16,+0v,x,4.5s,1.5s'; 
          break;
        case 5:
  
              //knead(鎹忋€佹弶)
          _config[oMassJsonKey.patName]={
              default: "knead(鎹忋€佹弶)",
          };
          _config[oMassJsonKey.patternCmd]='omass://pattern_254_10,16,+0,x,4.5,1.5;10,16,+0,x,4.5,1.5'; 
          break;
        case 6:
  
              //pound(鏁层€佹嵍)
          _config[oMassJsonKey.patName]={
              default: "pound(鏁层€佹嵍)",
          };
          _config[oMassJsonKey.patternCmd]='omass://pattern_254_200,16,+0,x,10,0;100,16,+0,x,10,0;667,16,+0,x,10,0';
          break;
        case 7:
              //1/f wave
          _config[oMassJsonKey.patName]={
              default: "1/f wave",
          };
          _config[oMassJsonKey.patternCmd]='omass://pattern_254_29,16,+12,x,1,0;29,16,+10,x,1,0;29,16,+8,x,2,0;20,16,+5,x,2,0;16,16,+0,x,4,0;20,16,+5,x,2,0;24,16,+5,x,2,0;29,16,+8,x,1,0;29,16,+10,x,1,0;29,16,+12,x,0.3,0';
  
          break;
        default:
              //1/f wave
          _config[oMassJsonKey.patName]={
              default: "1/f wave",
          };
          _config[oMassJsonKey.patternCmd]='omass://pattern_254_29,16,+12,x,1,0;29,16,+10,x,1,0;29,16,+8,x,2,0;20,16,+5,x,2,0;16,16,+0,x,4,0;20,16,+5,x,2,0;24,16,+5,x,2,0;29,16,+8,x,1,0;29,16,+10,x,1,0;29,16,+12,x,0.3,0';
  
          break;
      }
    oMassConfig[i]=_config;
}
console.log('oMassConfig2',oMassConfig);
var cookieOptions={
	modeType: {
		key: "modelType",
		default: modelTypeDef.Normal
	},
    uiMode: {
      key: 'uiMode', 
        default: '1', 
        options: {barView:'0',
                 normalView:'1',
                 }
    },
    readOnly: {
        key: 'readOnly',
        default: '0', 
        options: {writable:'0',
                  readonly:'1',
                 }, 
    },
    uiSize: {
        key: 'uiSizeX',
        default: '1', 
        options: {
            normal:'0',
            enlarge:'1',
        }
    },
    loadStus: {
        key: 'loadStus',
        default: '0', 
        options: {
            notLoaded: '0',
            loaded:'1'
        }
    },
    patSelect: {
        key: 'patSel',
        default: '0', 
        options: {m0:'0',
                  m1:'1',
                  m2:'2',
                  m3:'3',
                  m4:'4',
                  m5:'5',
                  m6:'6',
                  m7:'7',
                 }, 
    },
    timerSet: {
        key: 'timer',
        default: '3600', 
        options: {
        }, 
    },
//    freqSet: {
//        key: 'freq',
//        default: '0', 
//        options: {
//        }, 
//    },
//    widthSet: {
//        key: 'width',
//        default: '0', 
//        options: {
//        }, 
//    },
//    heightSet: {
//        key: 'height',
//        default: '3', 
//        options: {
//        }, 
//    },
    alarmMode: {
        key: 'alarm',
        default: 'true', 
        options: {
            On:'true', 
            Off:'false', 
        }, 
    },
    powerSaveMode: {
//        key: 'powerSave',
        key: 'powerSaveX',		// Change default
        default: 'false', 
        options: {
            On:'true', 
            Off:'false', 
        }, 
    },
//    dealSelected:{
//        key:'dealSelected',
//        default: '0',
//        options: {},
//    },
    locale: {
        key: 'locale',
        default:'en', 
    },
    uiTitle: {
        key: 'uiTitle',
        default:'',
    },
    patVoltage: {
        key: 'global-voltage',
        default: 0, 
    },
    deal0: {
        key: 'deal0',
        default: '2:'+(freqMapTab.length-1)+':4',       //Strength:Freq:width
        options:{},
        json: {          //
            key: 'json0', 
            default: "",
        },
        params: {
            voltage: {
                key: 'D0param0-voltage',
                default: 2, 
            },
            freqIos: {
                key: 'D0param1-freqIos',
                default: 33,        // 33Hz 
            },
            freq: {
                key: 'D0param1-freq',
                default: freqMapTab.length-1, 
            },
            width: {
                key: 'D0param2-width',
                default: 4,
            },
			maxFreq: {
				key: 'D0-maxF',
				default: -1,
			},
        }, 
        patName: {
            key: 'Pat0Name', 
            default: 'Deal 0',
        },
        patMode: {
            key: 'pat0Mode', 
            default: true,
            options:{
                DirectMode:false, 
                PatterMode:true,      
            }
        },
        controlItem: {          //
            key: 'pat0Control', 
            default: "true:true:true",      // Voltage:Freq:width
        },
        pattern: {          //
            key: 'pattern0', 
            default: "",
        },
    },
    deal1: {
        key: 'deal1',
        default: '3:0:4',
        options:{},
        json: {          //
            key: 'json1', 
            default: "",
        },
        params: {
            voltage: {
                key: 'D1param0-voltage',
                default: 3, 
            },
            freqIos: {
                key: 'D1param1-freqIos',
                default: 33,        // 33Hz 
            },
            freq: {
                key: 'D1param1-freq',
                default: 0, 
            },
            width: {
                key: 'D1param2-width',
                default: 4, 
            },
			maxFreq: {
				key: 'D1-maxF',
				default: -1,
			},
        }, 
        patName: {
            key: 'Pat1Name', 
            default: 'Deal 1',
        },
        patMode: {
            key: 'pat1Mode', 
            default: true,
            options:{
                DirectMode:false, 
                PatterMode:true,      
            }
        },
        controlItem: {          //
            key: 'pat1Control', 
            default: "true:true:true",      // Voltage:Freq:width
        },
        pattern: {          //
            key: 'pattern1', 
            default: "",
        },
    },
    deal2: {
        key: 'deal2',
        default: '3:0:4',
        options:{},
        json: {          //
            key: 'json2', 
            default: "",
        },
        params: {
            voltage: {
                key: 'D2param0-voltage',
                default: 3, 
            },
            freqIos: {
                key: 'D2param1-freqIos',
                default: 33,        // 33Hz 
            },
            freq: {
                key: 'D2param1-freq',
                default: 0, 
            },
            width: {
                key: 'D2param2-width',
                default: 4, 
            },
			maxFreq: {
				key: 'D2-maxF',
				default: -1,
			},
        }, 
        patName: {
            key: 'Pat2Name', 
            default: 'Deal 2',
        },
        patMode: {
            key: 'pat2Mode', 
            default: true,
            options:{
                DirectMode:false, 
                PatterMode:true,      
            }
        },
        controlItem: {          //
            key: 'pat2Control', 
            default: "true:true:true",      // Voltage:Freq:width
        },
        pattern: {          //
            key: 'pattern2', 
            default: "", 
        },
    },
    deal3: {
        key: 'deal3',
        default: '3:0:4',
        options:{},
        json: {          //
            key: 'json3', 
            default: "",
        },
        params: {
            voltage: {
                key: 'D3param0-voltage',
                default: 3, 
            },
            freqIos: {
                key: 'D3param1-freqIos',
                default: 33,        // 33Hz 
            },
            freq: {
                key: 'D3param1-freq',
                default: 0, 
            },
            width: {
                key: 'D3param2-width',
                default: 4, 
            },
			maxFreq: {
				key: 'D3-maxF',
				default: -1,
			},
        }, 
        patName: {
            key: 'Pat3Name', 
            default: 'Deal 3',
        },
        patMode: {
            key: 'pat3Mode', 
            default: true,
            options:{
                DirectMode:false, 
                PatterMode:true,      
            }
        },
        controlItem: {          //
            key: 'pat3Control', 
            default: "true:true:true",      // Voltage:Freq:width
        },
        pattern: {          //
            key: 'pattern3', 
            default: "", 
        },
    },
    deal4: {
        key: 'deal4',
        default: '3:0:4',
        options:{},
        json: {          //
            key: 'json4', 
            default: "",
        },
        params: {
            voltage: {
                key: 'D4param0-voltage',
                default: 3, 
            },
            freqIos: {
                key: 'D4param1-freqIos',
                default: 33,        // 33Hz 
            },
            freq: {
                key: 'D4param1-freq',
                default: 0, 
            },
            width: {
                key: 'D4param2-width',
                default: 4, 
            },
			maxFreq: {
				key: 'D4-maxF',
				default: -1
			},
        }, 
        patName: {
            key: 'Pat4Name', 
            default: 'Deal 4',
        },
        patMode: {
            key: 'pat4Mode', 
            default: true,
            options:{
                DirectMode:false, 
                PatterMode:true,      
            }
        },
        controlItem: {          //
            key: 'pat4Control', 
            default: "true:true:true",      // Voltage:Freq:width
        },
        pattern: {          //
            key: 'pattern4', 
            default: "", 
        },
    },
    deal5: {
        key: 'deal5',
        default: '3:0:4',
        options:{},
        json: {          //
            key: 'json5', 
            default: "",
        },
        params: {
            voltage: {
                key: 'D5param0-voltage',
                default: 3, 
            },
            freqIos: {
                key: 'D5param1-freqIos',
                default: 33,        // 33Hz 
            },
            freq: {
                key: 'D5param1-freq',
                default: 0, 
            },
            width: {
                key: 'D5param2-width',
                default: 4, 
            },
			maxFreq: {
				key: 'D5-maxF',
				default: -1
			},
        }, 
        patName: {
            key: 'Pat5Name', 
            default: 'Deal 5',
        },
        patMode: {
            key: 'pat5Mode', 
            default: true,
            options:{
                DirectMode:false, 
                PatterMode:true,      
            }
        },
        controlItem: {          //
            key: 'pat5Control', 
            default: "true:true:true",      // Voltage:Freq:width
        },
        pattern: {          //
            key: 'pattern5', 
            default: "", 
        },
    },
    deal6: {
        key: 'deal6',
        default: '3:0:4',
        options:{},
        json: {          //
            key: 'json6', 
            default: "",
        },
        params: {
            voltage: {
                key: 'D6param0-voltage',
                default: 3, 
            },
            freqIos: {
                key: 'D6param1-freqIos',
                default: 33,        // 33Hz 
            },
            freq: {
                key: 'D6param1-freq',
                default: 0, 
            },
            width: {
                key: 'D6param2-width',
                default: 4, 
            },
			maxFreq: {
				key: 'D6-maxF',
				default: -1
			},
        }, 
        patName: {
            key: 'Pat6Name', 
            default: 'Deal 6',
        },
        patMode: {
            key: 'pat6Mode', 
            default: true,
            options:{
                DirectMode:false, 
                PatterMode:true,      
            }
        },
        controlItem: {          //
            key: 'pat6Control', 
            default: "true:true:true",      // Voltage:Freq:width
        },
        pattern: {          //
            key: 'pattern6', 
            default: "", 
        },
    },
    deal7: {
        key: 'deal7',
        default: '3:0:4',
        json: {          //
            key: 'json7', 
            default: "",
        },
        params: {
            voltage: {
                key: 'D7param0-voltage',
                default: 3, 
            },
            freqIos: {
                key: 'D7param1-freqIos',
                default: 33,        // 33Hz 
            },
            freq: {
                key: 'D7param1-freq',
                default: 0, 
            },
            width: {
                key: 'D7param2-width',
                default: 4, 
            },
			maxFreq: {
				key: 'D7-maxF',
				default: -1
			},
        }, 
        options:{},
        patName: {
            key: 'Pat7Name', 
            default: 'Deal 7',
        },
        patMode: {
            key: 'pat7Mode', 
            default: true,
            options:{
                DirectMode:false, 
                PatterMode:true,      
            }
        },
        controlItem: {          //
            key: 'pat7Control', 
            default: "true:true:true",      // Voltage:Freq:width
        },
        pattern: {          //
            key: 'pattern7', 
            default: "", 
        },
    },
    remoteJson:{
        key:'RemoteJson',
        default:'', 
        options:{}, 
    },
    remoteJsonOK:{
        key:'RemoteJsonOK',
        default:'na', 
        options:{
			ok:'true',
			fail:'false'
		}
    },
    locale:{
        key:'locale',
        default:'en', 
    },
    strCookie:{
        key: 'strCookie',
        default:'false',
        options: {
            save :'true',
            ignore: 'false',
        }
    },
    iosSpecific:{
        key:'iosSpecific',
        default:'true', 
    },
    iOSDevType:{
        key:'oMassType',
        default:'old', 
        options: {
            old: 'old', 
            new: 'new'
        }
    }
}

var isOhaPaused=false;

var cookieNew = new function(){
	this.write=function(_key, _data) {
		if(oha_api.cookieRead(_key, null)!=null) {
			oha_api.cookieRemove(_key);
		}
		oha_api.wsWrite(_key, _data);
	}
	
	this.read=function(_key, _default) {
		var _read=oha_api.cookieRead(_key, null);
		if(_read!=null) {
			oha_api.cookieRemove(_key);
			oha_api.wsWrite(_key, _read);
			return _read;
		}
		return oha_api.wsRead(_key, _default);
	}
}

$(window).resize(function() {
//	console.log("window.resize");
	setsize();
});

$(window).on('load',function(){
    console.log('准备好了');

//	setcookies();
//	console.log("window.load");
//    console.log("Hello, new/files/script.js");
//    if(oha_api.getPlateForm()!="PC") {
    winHeight=$(window).height();
    winWidth=$(window).width();
    cookieInit();
    if(oha_api.getPlateForm()==oha_api.PlateFormOpt.Apple) {
        if(oha_api.cookieRead(cookieOptions.loadStus.key, cookieOptions.loadStus.default)==cookieOptions.loadStus.default){
            oha_api.cookieWrite(cookieOptions.loadStus.key, cookieOptions.loadStus.options.loaded);
//            console.log("new/files/script.js before href=default_old.html")
            document.location.href="../default_old.html";
//            console.log("new/files/script.js after href=default_old.html")
            return;
        }
    }
    else {
        if(oha_api.getPlateForm()==oha_api.PlateFormOpt.Android) {
            console.log("Android Platform") ;
            console.log(oha_api.cookieRead("d20171018", "false")) ;
            if(oha_api.cookieRead("d20171018", "false")=="top") {
                var i=parseInt(oha_api.cookieRead(cookieOptions.deal0.params.freq.key, "100"));
                if(i<=33) {
                    oha_api.cookieRemove(cookieOptions.deal0.params.freq.key);
                }
                oha_api.cookieWrite("d20171018", "true");
            }
               
        }
//        if(oha_api.cookieRead(cookieOptions.loadStus.key, cookieOptions.loadStus.default)!=cookieOptions.loadStus.default){
//            document.location.href="../default_old.html";
//            return;
//        }
        
    }
	$("#itemUiStyle").find(".setItemMsg").children(0).text(getLocaleStr(MsgString.UiStyleMenu));
	$("#itemUiStyle").hide();
	$("#itemAbout").find(".setItemMsg span").eq(0).text(getLocaleStr(MsgString.UiVerMenu));
	$("#setMenuTitleText.menuEndTxt").text(getLocaleStr(MsgString.TitleMenu));
	$("#setMenuEnd .menuEndTxt").text(getLocaleStr(MsgString.BackMenu));
	$("#titleIconR1").hide();
   
	setsize();
//    console.log("Read oha_controller_options.txt");
	addHelp();
    readJsonAll();
//    readJson("oha_controller_options.txt")
//    .done(function(_jdata){
////        console.log("window.ready read local oha_controller_options.txt OK");
//        parseConfigJson(_jdata);
//    })
//    .fail(function(_msg){
//        console.log("read local json file fail, message:"+_msg);
//    });
//    var _remoteJs=oha_api.cookieRead(cookieOptions.remoteJson.key, cookieOptions.remoteJson.default);
//    if(_remoteJs!==cookieOptions.remoteJson.default){
//        console.log("Read Remote json, url="+_remoteJs);
////        readJson(_remoteJs+"/oha_controller_options.js");
//        readJson("oha_controller_options.js", true, _remoteJs)
//        .done(function(_jdata){
//            parseConfigJson(_jdata, true, _remoteJs);
//        })
//        .fail(function(_msg){
//            console.log(_msg);
//        });
////        readPatFromRemoteJsFile(_remoteJs+"/oha_controller_options.js");
//    }
    oha_api.cookieWrite(cookieOptions.locale.key, oha_api.getLocale());
//	console.log("$:check point1")
//	console.log("$:check point2")
	$("body").show();

	oha_api.runOhaCmd("oha://orientation_false");
});

var longClickH=null;
function newClickF(_selector, _clickFunc) {
    console.log('点击运行');
	// var _targetList=document.querySelectorAll(_selector);
	// for(var i=0;i<_targetList.length;i++) {
	// 	var _x=_targetList[i];
	// 	if(oha_api.getPlateForm()=="PC") {
	// 		_x.addEventListener("mousedown", function(e) {
	// 			e.target.style.opacity="0.5";
	// 		}, false);
	// 		_x.addEventListener("mousecancel", function(e) {
	// 			e.target.style.opacity="1.0";
	// 		}, false);
	// 		_x.addEventListener("mouseup", function(e) {
	// 			e.target.style.opacity="1.0";
				_clickFunc();
		// 	}, false);
		// }
// 		else {
// 			_x.addEventListener("touchstart", function(e) {
// 				console.log("("+_selector+") touchstart")
// 				e.target.style.opacity="0.5";
// 			}, false);
// 			_x.addEventListener("touchcancel", function(e) {
// 				e.target.style.opacity="1.0";
// 			}, false);
// 			_x.addEventListener("touchend", function(e) {
// 				console.log("("+_selector+") touchend")
// 				e.target.style.opacity="1.0";
// //				_clickFunc();
// 				setTimeout(function(){		// Prevent launch other object's click function
					// _clickFunc();
			// 	}, 500);
			// }, false);
		// }
	// }
}

//var jsonQ=[];
function jsonObj(_fileName, _isRemote, _remoteUrl){
    var self=this;
    this.done=function(_f) {
        if(typeof _f == "function") {
            this.doneF=_f;
        }
        return this;
    };
    this.doneF=function(_jdata){
    }
    this.fail=function(_f) {
        if(typeof _f == "function") {
            this.failF=_f;
        }
        return this;
    };
    this.failF=function(_msg){
        
    };
    if(typeof _isRemote=="undefined") {
        _isRemote=false;
    }
    console.log("readJson("+_fileName+", "+_isRemote+")");
    var _hostName=window.location.hostname;
    if(_isRemote) {
        if(_hostName==get_hostname(_remoteUrl)) {
            _isRemote=false;
        }
    }
    let _url
    if(_isRemote){
        console.log(1,_remoteUrl);   
        _url=_remoteUrl+"/"+_fileName+"?Cache=false";
		console.log("jsonObj, remote URL="+_url);
        $.getScript(_url)
          .done(function( script, textStatus ) {
            console.log("jsonObj $getScript.done("+textStatus+")");
			var _jdata=oha_api.getJson();
			if(typeof _jdata=="object") {
				oha_api.cookieWrite(cookieOptions.remoteJsonOK.key, cookieOptions.remoteJsonOK.options.ok);
            	self.doneF(_jdata);
			}
			else {
				self.failF(_url+" is not a correct json file");
				oha_api.cookieWrite(cookieOptions.remoteJsonOK.key, cookieOptions.remoteJsonOK.options.fail);
			}
            
          })
          .fail(function( jqxhr, settings, exception ) {
            self.failF(_url+" load fail.");
			oha_api.cookieWrite(cookieOptions.remoteJsonOK.key, cookieOptions.remoteJsonOK.options.fail);
        });
    }
    else {
        console.log(2,_fileName);   
        _url=_fileName+"?Cache=false";
        console.log("before $.ajax, _url="+_url);
        $.ajax({                // Read Local JSON
          url:'https://www.allslug.com/txt/'+ _url,
          type: "GET",
          dataType: "json",
          success: function(Jdata) {
            console.log('成功了');
              self.doneF(Jdata);
          },

          error: function() {
            console.log('失败了');
              self.failF(_url+" load fail.");
          }
        });
    }
    
};

function get_hostname(url) {
    var m = url.match(/^http:\/\/[^/]+/);
    return m ? m[0] : null;
}

function getLocaleStr(StrObj) {
	  var _locale=oha_api.getLocale().localeCode;
	  var _localeX=_locale.split("-");
	  if(StrObj.hasOwnProperty(_locale)) {
		  return StrObj[_locale];
	  }
	  else if(StrObj.hasOwnProperty(_localeX[0])) {
		  return StrObj[_localeX[0]];
	  }
	  else if(StrObj.hasOwnProperty("default")) {
		  return StrObj.default;
	  }
}

function readJsonAll() {
//	console.log("##readJsonAll start");
    readJson("oha_controller_options.txt")
    .done(function(_jdata){
        console.log("window.ready read local oha_controller_options.txt OK");
       parseConfigJson(_jdata);
    })
    .fail(function(_msg){
        console.log("read local json file fail, message:"+_msg);
    });
//    var _remoteJs=oha_api.cookieRead(cookieOptions.remoteJson.key, cookieOptions.remoteJson.default);
    var _remoteJs=cookieNew.read(cookieOptions.remoteJson.key, cookieOptions.remoteJson.default);
    if(_remoteJs!==cookieOptions.remoteJson.default){
        console.log("Read Remote json, url="+_remoteJs);
//        readJson(_remoteJs+"/oha_controller_options.js");
		var _isOK=oha_api.cookieRead(cookieOptions.remoteJsonOK.key, cookieOptions.remoteJsonOK.options.default);
		if(_isOK!=cookieOptions.remoteJsonOK.options.fail) {
			readJson("oha_controller_options.js", true, _remoteJs)
			.done(function(_jdata){
				parseConfigJson(_jdata, true, _remoteJs);
			})
			.fail(function(_msg){
				console.log(_msg);
			});
		}
    }
}

function readJson(_fileName, _isRemote, _remoteUrl) {
    var _obj=new jsonObj(_fileName, _isRemote, _remoteUrl);
    return _obj;
}


function readPatJson(_fileName, _pNo, _isRemote, _remoteUrl) {
    console.log('222',_remoteUrl);
//    console.log("readPatJson cp0, _isRemote="+_isRemote);
    if(typeof _isRemote=="undefined") {
        _isRemote=false;
    }
    var _hostName=window.location.hostname;
    if(_isRemote) {
        if(_hostName==get_hostname(_remoteUrl)) {
            _isRemote=false;
        }
    }
    var _jsUrl=_fileName;
    if(_isRemote) {
        console.log(3,_fileName);   
        _jsUrl=_remoteUrl+"/"+_fileName+"?Cache=false";
//        $.getScript( "http://54.183.18.76/oMass/oha_controller_options.js" )
        $.getScript(_jsUrl)
          .done(function( script, textStatus ) {
//            console.log( textStatus );
            setPatternAttr(_pNo, oha_api.getJson());
          })
          .fail(function( jqxhr, settings, exception ) {
            console.log(_jsUrl+" load fail.")
        });
    }
    else {
//        console.log("readPatJson, isRemote=false, url="+(_jsUrl+"?Cache=false"));
console.log(4);  
let url = `https://allslug.happydoit.com/file/${_fileName}?Cache=false`;    
$.ajax({
          url: url,
          type: "GET",
          dataType: "json",
          success: function(Jdata) {
            // console.log(6666666);
    //          console.log("SUCCESS==>"+Jdata);
//              console.log("readPatJson, isRemote=false, url="+(_jsUrl+"?Cache=false"));
              if(typeof _pNo=="undefined") {
                  _pNo=0;
              }
         
//              var _config=oMassConfig[_pNo];
              setPatternAttr(_pNo, Jdata);
          },

          error: function() {
            console.log(_jsUrl+" ERROR!!!("+arguments[2]+")");
          }
        });
    }
}

function setPatternAttr(_pNo, _jobj) {
    // console.log(55555555555555);
    // console.log("setPatternAttr("+_pNo+")");
	if(_jobj==null) {
		return;
	}
      var _config=oMassConfig[_pNo];
      if(_jobj.hasOwnProperty(oMassJsonKey.patName)) {
          $.extend(_config[oMassJsonKey.patName], _jobj[oMassJsonKey.patName]);
          var _nameObj=_config[oMassJsonKey.patName];
          for(let x in _nameObj) {
//              console.log("key="+x+", value="+_nameObj[x]);
//              oha_api.cookieWrite(cookieOptions["deal"+_pNo].patName.key+":"+x, _nameObj[x]);
              oha_api.cookieWrite(cookieOptions["deal"+_pNo].patName.key+":"+x, _nameObj[x]);
//              _read=oha_api.cookieRead(cookieOptions["deal"+_pNo].patName.key+":"+x, cookieOptions["deal"+_pNo].patName.default);
             let _read=oha_api.cookieRead(cookieOptions["deal"+_pNo].patName.key+":"+x, cookieOptions["deal"+_pNo].patName.default);
//              console.log("setPatternAttr read cookie, locale="+x+", key="+cookieOptions["deal"+_pNo].patName.key+":"+x+", read="+_read);
          }
          var _name="";
          var _locale=oha_api.getLocale().localeCode;
          var _localeX=_locale.split("-");
          if(_nameObj.hasOwnProperty(_locale)) {
              _name=_nameObj[_locale];
          }
          else if(_nameObj.hasOwnProperty(_localeX[0])) {
              _name=_nameObj[_localeX[0]];
          }
          else if(_nameObj.hasOwnProperty("default")) {
              _name=_nameObj.default;
          }
          $("#patSel"+(_pNo+1)).attr("name", _name);
      }
      if(_jobj.hasOwnProperty(oMassJsonKey.patMode)) {      // Pattern Mode
          if(!_jobj[oMassJsonKey.patMode]) {
              _config[oMassJsonKey.patMode]=false;
              oha_api.cookieWrite(cookieOptions["deal"+_pNo].patMode.key, cookieOptions["deal"+_pNo].patMode.options.DirectMode);
          }
      }
      if(_jobj.hasOwnProperty(oMassJsonKey.voltageEn)) {
          _config[oMassJsonKey.voltageEn]=_jobj[oMassJsonKey.voltageEn];
      }
      if(_jobj.hasOwnProperty(oMassJsonKey.freqEn)) {
          _config[oMassJsonKey.freqEn]=_jobj[oMassJsonKey.freqEn];
      }
      if(_jobj.hasOwnProperty(oMassJsonKey.widthEn)) {
          _config[oMassJsonKey.widthEn]=_jobj[oMassJsonKey.widthEn];
      }
      if(_jobj.hasOwnProperty(oMassJsonKey.maxFreq)) {
          _config[oMassJsonKey.maxFreq]=_jobj[oMassJsonKey.maxFreq];
      }
        setPatDefault(_jobj, _pNo);
    
    var _enStr=_jobj[oMassJsonKey.voltageEn]+":"+_jobj[oMassJsonKey.freqEn]+":"+_config[oMassJsonKey.widthEn];
    oha_api.cookieWrite(cookieOptions["deal"+_pNo].controlItem.key, _enStr);
        
      if(_jobj.hasOwnProperty(oMassJsonKey.pattern)) {
//          if(!_config.hasOwnProperty(oMassJsonKey.pattern)) {
//              _config[oMassJsonKey.pattern]={};
//          }
//          $.extend(_config[oMassJsonKey.pattern], _jobj[oMassJsonKey.pattern]);
          _config[oMassJsonKey.pattern]=_jobj[oMassJsonKey.pattern];
      }
      if(_jobj.hasOwnProperty(oMassJsonKey.loop)) {
        
//          if(!_config.hasOwnProperty(oMassJsonKey.loop)) {
//              _config[oMassJsonKey.loop]={};
//          }
		  _config[oMassJsonKey.loop]=_jobj[oMassJsonKey.loop];
          _config[oMassJsonKey.patternCmd]=getPatCmd(_jobj[oMassJsonKey.loop], _config[oMassJsonKey.pattern]);
          cookieNew.write(cookieOptions["deal"+_pNo].pattern.key, _config[oMassJsonKey.patternCmd]);
      }
      oMassConfig[_pNo]=_config;
//      if(parseInt($("#patSelBlk").attr("value"))==_pNo) {
	  if(oha_api.cookieRead(cookieOptions.patSelect.key, cookieOptions.patSelect.default)==_pNo) {
          setPattern(_pNo);
      }
//	  cookieNew.write(cookieOptions["deal"+_pNo].json.key, JSON.stringify(_config));
	  cookieNew.write(cookieOptions["deal"+_pNo].json.key, JSON.stringify(_config));
}

//function readPatFromRemoteJsFile(_jsUrl){
//    $.getScript(_jsUrl)
//      .done(function( script, textStatus ) {
////            console.log( textStatus );
//        Jdata=oha_api.getJson();
//        parseConfigJson(Jdata);
//        for(var i=0;i<8;i++) {
//            var _key="Pattern_Mode_"+(i+1);
//            if(Jdata.hasOwnProperty(_key)) {
//                var _url=_jsUrl.substring(0,_jsUrl.lastIndexOf("/")+1)+Jdata[_key];
//                readPatJson(_url, i, true);
//            }
//        }   
//    })
//      .fail(function( jqxhr, settings, exception ) {
//        $( "div.log" ).text( "Triggered ajaxError handler." );
//    });
//}

function parseConfigJson(_jdata, _isRemote, _remoteUrl) {
    console.log("parseConfigJson start, _isRemote="+_isRemote);
//	if(typeof _jdata!="object") {
//		console.log("Error!!Not a jason data")
//		return;
//	}
    if(typeof _isRemote == "undefined") {
        _isRemote=false;
    }
//    console.log("parseConfigJson cp1, _isRemote="+_isRemote);
    setPatDefault(_jdata);
//    if(!forDebug) {
    setUi(_jdata);
//    }
    for(var i=0;i<8;i++) {
        var _key="Pattern_Mode_"+(i+1);
		switch(modelType) {
			case modelTypeDef.Face:
				if(i<4) {
					_key="Pattern_Face_Mode_"+(i+1);
				}
				break;
		}
        if(_jdata.hasOwnProperty(_key)) {
            console.log("_jdata[_key]="+_jdata[_key]);
            if(!$("#itemIosPat").hasClass("itemHide") && $("#itemIosPat").hasClass("iosPat")) {
                readPatJson("ios/"+_jdata[_key], i, _isRemote, _remoteUrl);
            }
            else {
                readPatJson(_jdata[_key], i, _isRemote, _remoteUrl);
            }
        }
    }
    console.log("parseConfigJson end, _isRemote="+_isRemote);
}

function setPatDefault(_jdata, _pNo) {
    console.log('进来了');
    if(typeof _pNo=="undefined") {
        _pNo=-1;
    }
    else if(_pNo>7) {
        _pNo=-1;
    }
    var _p={
        v:-1, 
        f:-1, 
        w:-1, 
    };
    if(_jdata.hasOwnProperty(oMassJsonKey.voltageDefault)) {
        var _voltage=_jdata[oMassJsonKey.voltageDefault];
        _p.v=Math.round(parseInt(_voltage)/10)-1;
    }
	var _freqMax=100;
	if(_jdata.hasOwnProperty(oMassJsonKey.maxFreq)) {
		_freqMax=parseInt(_jdata[oMassJsonKey.maxFreq]);
		_p.fmax=_freqMax;
	}
    if(_jdata.hasOwnProperty(oMassJsonKey.freqDefault)) {
//        oMassConfig[oMassJsonKey.freqDefault]=_jdata[oMassJsonKey.freqDefault];
        var _freq=parseFloat(_jdata[oMassJsonKey.freqDefault]);
        if(!$("#itemIosPat").hasClass("itemHide")) {
            if(_freqMax>33) {
                _freqMax=33;
        }
            }
        if(_freq>_freqMax) {
            _freq=_freqMax;
        }
        if(_freq<0.5) {
            _freq=0.5;
        }
        for(i=0;i<freqMapTab.length-1;i++) {
            if(Math.abs(_freq-freqMapTab[i])<Math.abs(freqMapTab[i+1]-_freq)) {
                _freq=i;
                break;
            }
            else {
                if(i==freqMapTab.length-2) {
                    _freq=i+1;
                    break;
                }
            }
        }
        _p.f=_freq;
    }
    if(_jdata.hasOwnProperty(oMassJsonKey.widthDefault)) {
        oMassConfig[oMassJsonKey.widthDefault]=_jdata[oMassJsonKey.widthDefault];
        var _width=parseInt(_jdata[oMassJsonKey.widthDefault]);
        if(_width>800) {
            _width=800;
        }
        else if(_width<25) {
            _width:25;
        }
        for(i=0;i<(widthMapTab.length-1);i++) {
            if(_width>=widthMapTab[i] && _width<=widthMapTab[i+1]) {
                if(_width-widthMapTab[i]<widthMapTab[i+1]-_width) {
                    _p.w=i;
                }
                else {
                    _p.w=i+1;
                }
                break;
            }
            if(i==(widthMapTab.length-2)){
                _p.w=i;
            }
        }
//        _p.w=Math.round(_width/50);
    }
    if((_p.v<0) && (_p.f<0) && (_p.w<0)) {
        return;
    }
    if(_p.v>=0) {
        if(oha_api.cookieRead(cookieOptions.patVoltage.key, cookieOptions.patVoltage.default)==cookieOptions.patVoltage.default) {
            for(var i=0;i<8;i++) {
                $("#patSel"+(i+1)).attr("param0",_p.v);
                console.log("setPatDefault, pattern="+i+", param0="+_p.v)
            }
        }
    }
    if(_pNo<0) {
        for(var i=0;i<8;i++) {
            var cookieParams=cookieOptions["deal"+i].params;
//            if(_p.v>=0) {
//                if(oha_api.cookieRead(cookieParams.voltage.key, cookieParams.voltage.default)==cookieParams.voltage.default) {
//                    $("#patSel"+(i+1)).attr("param0",_p.v);
//                    console.log("setPatDefault, pattern="+i+", param0="+_p.v)
//                }
//            }
            if(_p.f>=0) {
                if(isMax33Hz) {
					var _cookieF=oha_api.cookieRead(cookieParams.freqIos.key, cookieParams.freqIos.default);
                    if(_cookieF==cookieParams.freqIos.default) {
                        $("#patSel"+(i+1)).attr("param1",_p.f);
                        console.log("setPatDefault, pattern="+i+", param1="+_p.f)
                    }
					else {
						$("#patSel"+(i+1)).attr("param1",_cookieF);
					}
                }
                else {
					var _cookieF=oha_api.cookieRead(cookieParams.freq.key, cookieParams.freq.default);
                    if(_cookieF==cookieParams.freq.default) {
                        $("#patSel"+(i+1)).attr("param1",_p.f);
                        console.log("setPatDefault, pattern="+i+", param1="+_p.f)
                    }
					else {
						$("#patSel"+(i+1)).attr("param1",_cookieF);
					}
                    
                }
            }
            if(_p.w>=0) {
				var _cookieW=oha_api.cookieRead(cookieParams.width.key, cookieParams.width.default);
                if(_cookieW==cookieParams.width.default) {
                    $("#patSel"+(i+1)).attr("param2",_p.w);
                    console.log("setPatDefault, pattern="+i+", param2="+_p.w)
                }
				else {
					$("#patSel"+(i+1)).attr("param2",_cookieW);
				}
            }
        }    
		var _patDefault=cookieOptions.patSelect.default;
		if(_jdata.hasOwnProperty(oMassJsonKey.patDefault)) {
			_patDefault=parseInt(_jdata[oMassJsonKey.patDefault])-1;
		}
		var _pat=parseInt(oha_api.cookieRead(cookieOptions.patSelect.key, _patDefault));
		setPattern(_pat);
   }
    else {
        var cookieParams=cookieOptions["deal"+_pNo].params;
//        if(_p.v>=0) {
//            if(oha_api.cookieRead(cookieParams.voltage.key, cookieParams.voltage.default)==cookieParams.voltage.default) {
//                $("#patSel"+(_pNo+1)).attr("param0",_p.v);
//                console.log("setPatDefault, pattern="+_pNo+", param0="+_p.v)
//            }
//        }
        if(_p.f>=0) {
            if(isMax33Hz) {
				var _cookieF=oha_api.cookieRead(cookieParams.freqIos.key, cookieParams.freqIos.default);
                if(_cookieF==cookieParams.freqIos.default) {
                    $("#patSel"+(_pNo+1)).attr("param1",_p.f);
                    console.log("setPatDefault, pattern="+_pNo+", param1="+_p.f)
                }
				else {
					$("#patSel"+(_pNo+1)).attr("param1",_cookieF);
				}
            }
            else {
				var _cookieF=oha_api.cookieRead(cookieParams.freq.key, cookieParams.freq.default);
                if(_cookieF==cookieParams.freq.default) {
                    $("#patSel"+(_pNo+1)).attr("param1",_p.f);
                    console.log("setPatDefault, pattern="+_pNo+", param1="+_p.f)
                }
				else {
					$("#patSel"+(_pNo+1)).attr("param1",_cookieF);
				}
            }
        }
        if(_p.w>=0) {
			var _cookieW=oha_api.cookieRead(cookieParams.width.key, cookieParams.width.default);
            if(_cookieW==cookieParams.width.default) {
                $("#patSel"+(_pNo+1)).attr("param2",_p.w);
                console.log("setPatDefault, pattern="+i+", param2="+_p.w)
            }
			else {
				$("#patSel"+(_pNo+1)).attr("param2",_cookieW);
			}
        }
    }
}

function setUi(_json) {
    if(_json.hasOwnProperty(JsonKey.ui.key)) {
       let _uiJson=_json[JsonKey.ui.key];
       let _propJson=JsonKey.ui.property;
        if(_uiJson.hasOwnProperty(_propJson.title)) {
            oha_api.cookieWrite(cookieOptions.uiTitle.key, _uiJson[_propJson.title]);
            $("text#oha_title_2_").text(_uiJson[_propJson.title]);
        }
    }

}

//function runJsonJs(_count) {
//    count++;
//    if(count>100) {
//        return;
//    }
//    setTimeout(function(){
//        if(oha_api.__json==null) {
//            runJsonJs();
//        }
//        else {
//            for(var i=0;i<8;i++) {
//                var _key="Pattern_Mode_"+(i+1);
//                if(Jdata.hasOwnProperty(_key)) {
//                    readPatJson(Jdata[_key], i);
//                }
//            }            
//        }
//    },100);
//}

function getPatCmd(_loop, _pat) {
    console.log(_loop);
    console.log(666666666);
    if(_loop.hasOwnProperty(oMassJsonKey.sequence)) {
        console.log('准备切换模式');
        var _seq=_loop[oMassJsonKey.sequence];
        var _cmd="omass://pattern_254_";
        for(i=0;i<_seq.length;i++) {
            _cmd+=getCmdFromPatName(_seq[i], _pat);
            if(i!==(_seq.length-1)) {
                _cmd+=";";
            }
        }
        console.log("getPatCmd="+_cmd);
        return _cmd;
    }
    else {
        return null;
    }
}

function getCmdFromPatName(_patName, _patSet) {
    var _cmd="";
	var i=parseInt($("#FreqCtrlBlk").attr("value"));
	var _freq=freqMapTab[i];
//	console.log("getCmdFromPatName:i="+i+", _freq="+_freq);
	var _period=Math.round(1000/_freq);
//	var _width=widthMapTab[parseInt($("#WidthCtrlBlk").attr("value"))];
	var _width=parseInt($("#WidthCtrlBlk").attr("value"));
	var _gapTime=parseInt($("#ModeCtrlBlk").attr("value"));
    if(_patSet.hasOwnProperty(_patName)) {
        var _p=_patSet[_patName];
		var _prd=_p.Period;
		if(_prd=="$P") {
			_prd=_period;
		}
		var _wid=_p.Width;
		if(_wid=="$W") {
			_wid=_width;
		}
		var _gap=_p.Gap_Time;
		if(_gap=="$EP0") {
			_gap=($("#ModeCtrlBlk").attr("value")=="0")?0:60;
		}
        _cmd+=_prd+","+_wid+","+_p.Voltage+","+_p.Direction+","+_p.OP_Time+","+_gap;
		console.log("getCmdFromPatName("+_patName+"), _cmd="+_cmd);
        return _cmd;
    }
    else {
        throw "Error!!";
    }
}

function setsize() {
	console.log("setsize")
//	var _w=window.innerWidth;
	var _w=$(window).width();
//    var _h=window.outerWidth;
//	var _h=window.innerHeight;
	var _h=$(window).height();
    console.log("setsize, window width="+_w+", window.height="+_h);
    setUiSize();
    let sf=_h*0.6;
	$("h2").css("font-size",sf*0.05+"px");
	$("h3").css("font-size",sf*0.035+"px");
	$("h4").css({"font-size":sf*0.03+"px", "margin-top":sf*0.005+"px", "margin-botton":sf*0.005+"px"});
	$("#status").css({"width":($(window).width()*14)/100,"height":($(window).width()*14)/100, "margin":"auto"});
	$("#strbtnText").css({"font-size":($(window).width()*7)/100+"px","line-height":($(window).width()*14)/100+"px"});
    // if(oha_config.lang=="EN") {
    // 	$(".modelist").css({"width":($(window).width()*12)/100,"height":($(window).width()*12)/100, "font-size":($(window).width()*3)/100+"px","line-height":($(window).width()*12)/100+"px"});
    // }
    // else {
    	$(".modelist").css({"width":($(window).width()*12)/100,"height":($(window).width()*12)/100, "font-size":($(window).width()*4)/100+"px","line-height":($(window).width()*12)/100+"px"});
    // }
    $(".modebtn").css({"font-size":($(window).width()*4)/100+"px","width":($(window).width()*12)/100+"px", "border-radius":sf*0.02+"px"});
	$(".strctrl").css({"width":($(window).width()*9)/100,"height":($(window).width()*9)/100});
	$(".timelist").css({"width":($(window).width()*12)/100,"height":($(window).width()*12)/100, "font-size":($(window).width()*5)/100+"px","line-height":($(window).width()*12)/100+"px"});
	$(".timer").css({"font-size":$(window).width()*0.18+"px"});
	$("#titleDiv").css({"font-size":0.06*_w+"px"});
	$(".detectpic").css({"width":sf*0.07,"height":sf*0.07});
	$("#re-connect").css({"width":sf*0.1,"height":sf*0.1});
	$(".loadingdot").css({"width":($(window).width()*2)/100,"height":($(window).width()*2)/100});
	$(".powerbtn, .powctrlbtn").width($(window).width()*0.2);
	$(".powerbtn, .powctrlbtn").height($(window).width()*0.2);
    $(".powerbtn h3, .powctrlbtn h3").css({"top":$(window).width()*0.2+"px"})
    // $("#powerSaveMsg").text(oha_config.pwrSaveMsg);
	$("#settings").css({'width':sf*0.1,'height':sf*0.1});
	$("#setMenuDiv").css({'border-width':sf*0.007});
	$("#setMenuEnd").css({'border-bottom-left-radius':sf*0.01, 'border-bottom-right-radius':sf*0.01, 'border-width':sf*0.003+'px'});
	$(".setMenuContent").css({'border-width':sf*0.001+'px'});
    $("#bottomBarSvg").css({'height':Math.ceil(_w*0.2)+'px'});
	var _w1=_w;
	var _h1=_h*0.4;
//	var _h1=$("#main_ctrl>.patBlock").height();
	console.log("setsize, _w1="+_w1+", _h1="+_h1);
	if(_w1*34/48>_h1) {
		var _h2=(_h1*48)/34;
//		$("#main_ctrl>.patBlock").css({left:0.5*(_w1-(_h1*48/34))+"px",width:(_h1*48/34)+"px", height:""});
		$("#main_ctrl>.patBlock").css({left:"0px",width:"", height:""});
	}
	else {
		$("#main_ctrl>.patBlock").css({left:0, width:"", height:_w1*34/48+"px"});
	}
}

var isLandscape=false;
function setUiSize() {
//    var _w=window.innerWidth;
//    var _w=window.outerWidth
	var _w=$(window).width();
//    console.log("setsize, window width="+_w+", window.innerWidth="+window.innerWidth);
//    var _h=window.innerHeight;
	var _h=$(window).height();
	console.log("setUiSize, _w="+_w+", _h="+_h);
//    var _h=window.outerHeight;
    var _mainH=0;
    var _mainW=0;
    var _sizeFactor=0.8;
	var _landScapeFactor=1200/800;
	let sf=_h*0.6;
	if(sf>_w) {
		sf=_w;
        _mainW=_w;
        _mainH=_mainW/0.6;
		isLandscape=false;
	}
	else if(_h>_w) {
		_mainH=_h;
		_mainW=_h*0.6;
		isLandscape=false;
	}
	else if(_h*_landScapeFactor>_w) {
		_mainW=_w;
		_mainH=_w/_landScapeFactor;
		isLandscape=true;
	}
    else {
        _mainH=_h;
        _mainW=_h*_landScapeFactor;
		isLandscape=true;
    }
    $("#timerAndIcon .timerShow").css({"line-height":$("#timerAndIcon .timerShow").height()+"px"});
    if($("#itemUiSize").hasClass("enlarge")) {
        _sizeFactor=1.0;
        oha_api.cookieWrite(cookieOptions.uiSize.key, cookieOptions.uiSize.options.enlarge);
//        $("#timerAndIcon>.stusShow").css("font-size","2.5vh");
        $("#timerAndIcon>.stusShow").css("font-size",0.04*sf+"px");
		$("#titleIconR1,#titleIconRq").addClass("enlarge");
		$("#titleIconR1 g.enlarge").hide();
		$("#titleIconR1 g.shrink").show();
        $(".main").addClass("enlarge");
    }
    else {
        oha_api.cookieWrite(cookieOptions.uiSize.key, cookieOptions.uiSize.options.normal);
        $("#timerAndIcon>.stusShow").css("font-size",0.03*sf+"px");
		$("#titleIconR1,#titleIconRq").removeClass("enlarge");
		$("#titleIconR1 g.enlarge").show();
		$("#titleIconR1 g.shrink").hide();
        $(".main").removeClass("enlarge");
    }
	_mainH=_mainH*_sizeFactor;
	_mainW=_mainW*_sizeFactor;
//    var _mainTop=(_h-_mainH)/2;
//    var _mainLeft=(_w-_mainW)/2;
    var _mainTop=(_h-_mainH)/2;
    var _mainLeft=(_w-_mainW)/2;
	if(isLandscape) {
		setOrientation(true, _mainH, _mainW);
	}
	else {
		setOrientation(false, _mainH, _mainW);
	}
}

function setOrientation(_isLandscape, _mainH, _mainW) {
	if(_isLandscape) {
    	$("#powerSaveMsg").css({"font-size":_mainH*0.1+"px"});
		$("#main_ctrl .patBlock").css({height:"80%", bottom:"-16%"});
		$("#patSel1,#patSel2,#patSel3,#patSel4").attr({transform:"matrix(1,0,0,1,-120,0)"});
		$("#patSel5,#patSel6,#patSel7,#patSel8").attr({transform:"matrix(1,0,0,1,120,0)"});
		$("#add,#reduce,#clock_icon").attr({transform:"matrix(1,0,0,1,0,60)"});
		$("#button_sound").attr({transform:"matrix(1,0,0,1,-100,0)"});
		$("#powerSaveBtn").attr({transform:"matrix(1,0,0,1,100,0)"});
		$("#timerAndIcon .timerShow").css({"font-size":_mainH*0.15+"px", "line-height":_mainH*0.15+"px"});
		$(".timerSelDiv").css({"font-size":_mainH*0.06+"px", "line-height":_mainH*0.06+"px"});
		$("#main_ctrl").css({top:"12.5%",height:"60%"});
		$("#titleDiv").css({height:"12.5%"});
		$("#powerctrl").css({top:"74%", height:"26%"});
		$("#titleIconRq").css({width:0.125*_mainH+"px", height:0.125*_mainH+"px"});
		setHelpOrientation(true);
	}
	else {
    	$("#powerSaveMsg").css({"font-size":_mainH*0.06+"px"});
		$("#main_ctrl .patBlock").css({height:"", bottom:""});
		$("#patSel1,#patSel2,#patSel3,#patSel4").removeAttr("transform");
		$("#patSel5,#patSel6,#patSel7,#patSel8").removeAttr("transform");
		$("#add,#reduce,#clock_icon").removeAttr("transform");
		$("#button_sound").removeAttr("transform");
		$("#powerSaveBtn").removeAttr("transform");
		$("#timerAndIcon .timerShow").css({"font-size":"", "line-height":""});
		$(".timerSelDiv").css({"font-size":_mainH*0.05+"px", "line-height":_mainH*0.05+"px"});
		$("#main_ctrl").css({top:"", height:""});
		$("#titleDiv").css({height:"8%"});
		$("#powerctrl").css({top:"", height:""});
		$("#titleIconRq").css({width:"", height:""});
		setHelpOrientation(false);
	}
}

function setHelpOrientation(_isLandscape) {
	if(!$("#titleIconRq").hasClass("isShow")) {
		return;
	}
	if(_isLandscape) {
		$(".helpMask").addClass("isLandscape")
	}
	else {
		$(".helpMask").removeClass("isLandscape")
	}
	var _mainH=$("div.main").height();
	if(_isLandscape) {
//			$(".helpMask").css({"font-size":0.05*_mainH});
		$(".helpMask").css({"line-height":0.06*_mainH+"px"});
		$("#output14Mask").attr({transform:"matrix(1,0,0,1,-120,0)"})
		$("#output58Mask").attr({transform:"matrix(1,0,0,1,120,0)"})
		$("#addSubTimerMask").attr({transform:"matrix(1,0,0,1,0,60)"});
		$(".noteHelp.helpMask .helpContainer,.powerSaveHelp.helpMask .helpContainer").css({"font-size":_mainH*0.04+"px"});
		$("#soundMask").hide();
		$(".noteHelp.helpMask, .powerSaveHelp.helpMask").show();

	}
	else {
		$(".helpMask").css({"line-height":0.05*_mainH+"px"});
		$("#output14Mask").removeAttr("transform");
		$("#output58Mask").removeAttr("transform");
		$("#addSubTimerMask").removeAttr("transform");
		$("#soundMask").show();
		$(".noteHelp.helpMask, .powerSaveHelp.helpMask").hide();
	}
}



//alert("Hello, othe TENS");
var DeviceId=-1;

  function setstr(n){
//	 oha_api.runOhaCmd('omass://str_'+n); 
	oha_api.runOhaCmd('omass://str_'+n); 
	 $("#status div,#xStrOmass text").text(n);
       switch(n){
          case "1":
          case "2":
          case "3":
          case "4":
              $("#xStrOmass text").attr({"fill":"green"});
              break;
          case "5":
          case "6":
          case "7":
          case "8":
              $("#xStrOmass text").attr({"fill":"yellow"});
              break;
          default:
              $("#xStrOmass text").attr({"fill":"red"});
      }
	 }
	 
//function defaultmode(){
//	setOpMode(0);
//}

function addHelp() {
	$("div#timerAndIcon").append("<div class='timerHelp helpMask verticalTxt'><div class='helpContainer'></div></div>\
	<div class='noteHelp helpMask verticalTxt'><div class='helpContainer'></div></div>\
	<div class='powerSaveHelp helpMask verticalTxt'><div class='helpContainer'></div></div>\
	<div class='menuHelp helpMask verticalTxt'><div class='helpContainer'></div></div>");
	var _str1="<span>Current Time<br>& Parameters</span>"
	var _str2="<span>Add/Sub time</span>"
	var _str3="<span>ON/OFF<br>Click to change</span>";
	var _str4="<span>Mini App<br>URL</span>";
	var _str5="<span>Bar<br>View</span>";
	var _str6="<span>Sound</span>";
	var _str7="<span>Power</span>";
	var _mainH=$("div.main").height();
	oha_api.getLocale().done(function(_localCode) {
//		console.log("oha_api.getLocale.done, _localCode="+_localCode+", oha_api.langSet="+oha_api.langSet);
		if(oha_api.langSet==oha_api.isoLangs.Chinese) {
			$(".helpMask .helpContainer").css({"font-size":"4vh"});
			if(oha_api.countryCode==oha_api.isoCountries.China) {
				_str1="<span>目前时间<br>与设定参数说明</span>";
				_str2="<span>加减时间设定</span>";
				_str3="<span>开关状态<br>触动可改变</span>";
				_str4="<span>小程序<br>网址</span>";
				_str5="<span>Bar<br>View</span>";
				_str6="<span>提醒音</span>";
				_str7="<span>省电</span>";
			}
			else {
				_str1="<span>目前時間<br>與設定參數說明</span>";
				_str2="<span>加減時間設定</span>";
				_str3="<span>開關狀態<br>觸動可改變</span>";
				_str4="<span>小程序<br>網址</span>";
				_str5="<span>Bar<br>View</span>";
				_str6="<span>提醒音</span>";
				_str7="<span>省電</span>";
			}
		}
	});
	$(".timerHelp .helpContainer").append(_str1);
	$(".menuHelp .helpContainer").append("<span>Menu</span>");
	$("#clock_icon").parent().append("<div class='timerChgHelp helpMask verticalTxt'><div class='helpContainer'></div></div>")
	$(".timerChgHelp .helpContainer").append(_str2);
	$("#powerctrl").append("<div class='powerHelp helpMask verticalTxt'><div class='helpContainer'></div></div>\
	<div class='wwwHelp helpMask verticalTxt'><div class='helpContainer'></div></div>\
	<div class='barHelp helpMask verticalTxt'><div class='helpContainer'></div></div>")
	$(".powerHelp .helpContainer").append(_str3);
	$(".wwwHelp .helpContainer").append(_str4);
	$(".barHelp .helpContainer").append(_str5);
	$(".noteHelp .helpContainer").append(_str6);
	$(".powerSaveHelp .helpContainer").append(_str7);
}

 
function pad(number, length) {
    var str = '' + number;
    while (str.length < length) {str = '0' + str;}
    return str;
}
function formatTime(time) {
    var min = parseInt(time / 6000),
        sec = parseInt(time / 100) - (min * 60);
        /*hundredths = pad(time - (sec * 100) - (min * 6000), 2)*/;
    return (min > 0 ? pad(min, 2) : "00") + ":" + pad(sec, 2);
}

function setPattern(i) {
    console.log("setPattern("+i+") start");
//	if(typeof isSync=="undefined") {
//		isSync=false;
//	}
	oMassItemConfig=oMassConfig[i];
    var _selIdx=parseInt($("#patSelBlk").attr("value"));
    $("#patSelBlk>g").eq(_selIdx).find("path").eq(0).attr("fill", "rgba(0,0,0,0)");
    $("#patSelBlk>g").eq(_selIdx).find("path").slice(1,3).attr("fill", "#FFFFFF");
//    $("#patSelBlk>g").eq(i).find("path").eq(0).attr("fill", "#C0C0C0");
    $("#patSelBlk>g").eq(i).find("path").slice(1,3).attr("fill", "#F29600");
    $("#patSelBlk").attr("value", i);
//    var _param=$("#patSel"+(i+1)).attr("param");
//    if(_param =="undefined") {
//        _param=cookieOptions["deal"+i].default;
//        $("#patSel"+(i+1)).attr({"param":_param});
//    }
    var _param0=$("#patSel"+(i+1)).attr("param0");
    if(parseInt(_param0) != NaN) {
//        _param0=cookieOptions["deal"+i].params.voltage.default;
//        _param0=cookieOptions.patVoltage.default;
        _param0=oha_api.cookieRead(cookieOptions.patVoltage.key, cookieOptions.patVoltage.default);
        $("#patSel"+(i+1)).attr({"param0":_param0});
    }
    var _param1=$("#patSel"+(i+1)).attr("param1");
    if(parseInt(_param1) != NaN) {
        if(isMax33Hz) {
//			_value=freqMapTab[i];
//            _param1=cookieOptions["deal"+i].params.freqIos.default;
			_param1=oha_api.cookieRead(cookieOptions["deal"+i].params.freqIos.key, cookieOptions["deal"+i].params.freqIos.default);
            if(parseInt(_param1) ==NaN) {
                _param1 = cookieOptions["deal"+i].params.freqIos.default;
            }
        }
        else {
//            _param1=cookieOptions["deal"+i].params.freq.default;
			_param1=oha_api.cookieRead(cookieOptions["deal"+i].params.freq.key, cookieOptions["deal"+i].params.freq.default);
            if(parseInt(_param1) ==NaN) {
                _param1 = cookieOptions["deal"+i].params.freq.default;
            }
        }
        $("#patSel"+(i+1)).attr({"param1":_param1});
    }
    var _param2=$("#patSel"+(i+1)).attr("param2");
    if(parseInt(_param2) !=NaN) {
		_param2=oha_api.cookieRead(cookieOptions["deal"+i].params.width.key, cookieOptions["deal"+i].params.width.default);
//        _param2=cookieOptions["deal"+i].params.width.default;
        if(parseInt(_param2)==NaN) {
            _param2=cookieOptions["deal"+i].params.width.default;
        }
        $("#patSel"+(i+1)).attr({"param2":_param2});
    }
    var _name=$("#patSel"+(i+1)).attr("name");
//    console.log("setPattern("+i+"), Read name="+_name+")");
    if(_name!=null) {
        $("#patNameTxt").text(_name);
    }
    else {
//        _name=oha_api.cookieRead(cookieOptions["deal"+i].patName.key, cookieOptions["deal"+i].patName.default);
        _name=oha_api.cookieRead(cookieOptions["deal"+i].patName.key, cookieOptions["deal"+i].patName.default);
        $("#patSel"+(i+1)).attr({"name":_name});
        $("#patNameTxt").text(_name);
    }
//    var _plist=_param.split(":");
//    setHeight(parseInt(_plist[0]));
//    setFreq(parseInt(_plist[1]));
//    setWidth(parseInt(_plist[2]));
    setHeight(parseInt(_param0));
    setFreq(parseInt(_param1), false);
    setWidth(parseInt(_param2), false);
    $("#level").show();
    $("#level").attr({"enable":"true"});
    $("#WidthCtrlBlk").show();
    $("#WidthCtrlBlk").attr({"enable":"true"});
    $("#FreqCtrlBlk").show();
    $("#FreqCtrlBlk").attr({"enable":"true"});
    $("#stusHItem").show();
    $("#stusWItem").show();
    $("#stusWItem").show();
    $("#stusFItem").show();
    if(oMassConfig[i].hasOwnProperty(oMassJsonKey.voltageEn)){
        if(!oMassConfig[i][oMassJsonKey.voltageEn]) {
            disHeightCtrl();
        }
    }
    if(oMassConfig[i].hasOwnProperty(oMassJsonKey.widthEn)){
        if(!oMassConfig[i][oMassJsonKey.widthEn]) {
            disWidthCtrl();
        }
    }
    if(oMassConfig[i].hasOwnProperty(oMassJsonKey.freqEn)){
        if(!oMassConfig[i][oMassJsonKey.freqEn]) {
            disFreqCtrl();
        }
        else {
             var _value=parseInt($("#FreqCtrlBlk").attr("value"));

          }

    }
    if(isDevReady) {
//            console.log("omass://custPat_"+i);
        sendPatternCmd();
    }
    oha_api.cookieWrite(cookieOptions.patSelect.key, i);
}

function disHeightCtrl() {
    $("#level").hide();
    $("#level").attr({"enable":"false"});
    $("#stusHItem").hide();
    
}

function disWidthCtrl() {
    $("#WidthCtrlBlk").hide();
    $("#WidthCtrlBlk").attr({"enable":"false"});
    $("#stusWItem").hide();
}

function disFreqCtrl() {
    $("#FreqCtrlBlk").hide();
    $("#FreqCtrlBlk").attr({"enable":"false"});
    $("#stusFItem").hide();
}

function showMsg(_str) {
    oha_api.runOhaCmd("oha://showToast_"+encodeURI(_str));
}

function sendPatternCmd() {
console.log('现在是' + localStorage.getItem('activeindex'));
    // var i=parseInt($("#patSelBlk").attr("value")); //那种模式
    var i = localStorage.getItem('activeindex')
    var _config=oMassConfig[i];
    
    console.log('oMassConfig',oMassConfig);
    console.log( '_config');
    console.log(_config);
    console.log(oMassJsonKey);
    if(!_config[oMassJsonKey.patMode]) {
//        oha_api.runOhaCmd("omass://opmode_0");
        oha_api.runOhaCmd("omass://opmode_2");
        sendParam();
    }
    else {
//        oha_api.runOhaCmd("omass://opmode_1");
        oha_api.runOhaCmd("omass://opmode_3");
        sendParam();
//        showMsg(_config[oMassJsonKey.patName]);
       let _patCmd=_config[oMassJsonKey.patternCmd];
       console.log('_patCmd',_patCmd);
//        console.log("sendPatternCmd("+_patCmd+")");
        oha_api.runOhaCmd(_patCmd);
    }
}

function setFreq(i, isUpdate) {
    console.log('准备塞入频率');
//	console.log("***setFreq("+i+")")
	if(typeof(isUpdate)==undefined) {
		isUpdate=true;
	}
	if(isMax33Hz && (i>33)) {
		i=33;
	}
   var _value=0;
//    if(i==0) {
//        _value=0.5;
//    }
//    else {
//        _value=i;
//    }
    _value=freqMapTab[i];
    var _sel=parseInt(localStorage.getItem('activeindex'));
    if(isMax33Hz) {
        oha_api.cookieWrite(cookieOptions["deal"+_sel].params.freqIos.key, i);
    }
    else {
        oha_api.cookieWrite(cookieOptions["deal"+_sel].params.freq.key, i);
    }

	if(isUpdate) {
		updateCmd(_sel);
	}
    
}

//function sendFreqCmd() {
//    var i=parseInt($("#FreqCtrlBlk").attr("value"));
//    console.log("freq="+i+", param="+freqParamTab[i]);
//    oha_api.runOhaCmd("omass://freq1905_"+freqParamTab[i]);
//}

function setWidth(i, isUpdate) {
    console.log('准备塞入脉波');
	if(typeof(isUpdate)==undefined) {
		isUpdate=true;
	}
    var _value=widthMapTab[i];
    var _sel=parseInt(localStorage.getItem('activeindex'));

    oha_api.cookieWrite(cookieOptions["deal"+_sel].params.width.key, i);
	if(isUpdate) {
		updateCmd(_sel);
	}

}
    
//function sendWidthCmd() {
//    var i=parseInt($("#WidthCtrlBlk").attr("value"));
//    console.log("width="+i+", param="+widthParamTab[i]);
//    oha_api.runOhaCmd("omass://width1905_"+widthParamTab[i]);
//}

function setHeight(i) {
//	console.log("***setHeight("+i+")")
//    var _value=(i+1)*5;
    var _c="#25ade3";
    console.log('塞入高度');
    console.log(oha_api.cookieWrite(cookieOptions.patVoltage.key, i));
    oha_api.cookieWrite(cookieOptions.patVoltage.key, i);      
}

function updateCmd(i) {
//	console.log("***updateCmd")
	var _config=oMassConfig[i];
	if(_config.hasOwnProperty(oMassJsonKey.loop)) {
        console.log('有进来了');
		sendParam_config[oMassJsonKey.patternCmd]=getPatCmd(_config[oMassJsonKey.loop], _config[oMassJsonKey.pattern]);
		oMassConfig[i]=_config;
	}
    console.log('没有进来啊');
	if(isStarted) {
		sendPatternCmd();
	}
}

function sendParam() {
console.log('准备输入设备');
    var _f=parseInt(localStorage.getItem('frequencyindex'));//频率
    var _w=parseInt(localStorage.getItem('waveindex')); //脉波
    var _h=parseInt($("#level").text());
    if(!_w){
        _w = 0
    }
    if(!_f){
        _f = 0
    }
    if(!_h){
        _h = 10
    }
    isDevReady = true
    console.log(_f,_w,_h);
//    console.log("omass://param1905_"+strParamTab[_h]+"_"+freqParamTab[_f]+"_"+widthParamTab[_w]);
//    oha_api.runOhaCmd("omass://param1905_"+strParamTab[_h]+"_"+freqParamTab[_f]+"_"+widthParamTab[_w]);
	if(isAD983x) {
        console.log('有');
		var _ret=oha_api.runOhaCmd("oha://cr_"+oha_api.DeviceId+"_G:06:00");
		if(_ret!='') {
			var _num=_ret.split(":");
			AD983xObj.baseF=parseInt(_ret, 16)*1000;
		}
	}
	else {
        console.log('没有');
//		var _volt=(_h+1)*10;
//		console.log("omass://param_"+strParamTab[_h]+"_"+freqParamTab[_f]+"_"+widthParamTab[_w]);
		console.log("omass://param_"+_h+"_"+_f+"_"+widthParamTab[_w]);
//		oha_api.runOhaCmd("omass://param_"+strParamTab[_h]+"_"+freqParamTab[_f]+"_"+widthParamTab[_w]);
		oha_api.runOhaCmd("omass://param_"+_h+"_"+_f+"_"+widthParamTab[_w]);
	}
}


function setAlarm(_isOn) {
    if(_isOn) {
        $("#itemSound").addClass(classDef.alarmMode);
        $("#itemSound").find(".setItemIcon").css("background-image", "url('files/sound_on.png')");
        $("g#xBtnSound line").hide();
        $("#button_sound line").hide();
        $("#itemSound").find(".setItemMsg").children(0).text(getLocaleStr(MsgString.SoundMenu.On));
        oha_api.runOhaCmd("oha://loudness_-2");
        oha_api.cookieWrite(cookieOptions.alarmMode.key, cookieOptions.alarmMode.options.On);
    }
    else {
        $("#itemSound").removeClass(classDef.alarmMode);
        $("#itemSound").find(".setItemIcon").css("background-image", "url('files/sound_off.png')");
//		$(this).find(".setItemMsg h4").text("Alert sound off");
        $("#itemSound").find(".setItemMsg").children(0).text(getLocaleStr(MsgString.SoundMenu.Off));
        $("#button_sound line").show();
        $("g#xBtnSound line").show();
        oha_api.runOhaCmd("oha://loudness_-1");
        oha_api.cookieWrite(cookieOptions.alarmMode.key, cookieOptions.alarmMode.options.Off);
    }
}

    function setPowerMode(_isOn, _isInit) {
		if(typeof(_isInit)==undefined) {
			_isInit=false;
		}
        if(_isOn) {
            $("#itemPowerMode").addClass(classDef.powerMode);
            $("#itemPowerMode").find(".setItemIcon").css("background-image", "url('files/light_off.png')");
            $("#itemPowerMode").find(".setItemMsg").children(0).text(getLocaleStr(MsgString.PowerSaveMenu.On));
            $("#powerSaveBtn,#xPowerSaveBtn").css({"opacity":"1.0"});
            var oha_power=($(".powerbtn.active").css("display")!="none");
            if(oha_power && !_isInit) {
                powerSaveOn();
    //            $("#pwrSaveBtn").show();
                $(".setMenuClass").hide();
    //            console.log("lightness-1")
//                oha_api.runOhaCmd("oha://lightness_-1");
            }
    //        console.log("cookieWrite(isPowerSave, true)");
            oha_api.cookieWrite(cookieOptions.powerSaveMode.key, cookieOptions.powerSaveMode.options.On);
        }
        else {
            $("#itemPowerMode").removeClass(classDef.powerMode);
            $("#itemPowerMode").find(".setItemIcon").css("background-image", "url('files/light_on.png')");
            $("#itemPowerMode").find(".setItemMsg").children(0).text(getLocaleStr(MsgString.PowerSaveMenu.Off));
            $("#powerSaveBtn,#xPowerSaveBtn").css({"opacity":"0.5"});
    //		$(this).find(".setItemMsg h4").text("Power saving mode off");
    //        $("#pwrSaveBtn").hide();
            if(oha_power=="on") {
                oha_api.runOhaCmd("oha://lightness_-2");
            }
    //        console.log("cookieWrite(isPowerSave, false)");
           oha_api.cookieWrite(cookieOptions.powerSaveMode.key, cookieOptions.powerSaveMode.options.Off);
        }
    }

	function powerOffBtnClick(_isNotSendCmd){
        console.log('开始运行');
		if(typeof _isNotSendCmd=="undefined") {
			_isNotSendCmd=false;
		}
        if(isOhaPaused) {
            return;
        }
		if(!_isNotSendCmd) {
            console.log('输入时间至于cmd');
			startOutput();
			// if($("#itemPowerMode").hasClass(classDef.powerMode)) {
			// 	powerSaveOn();
			// }
		}
		oha_timer.play();
    }

 	function powerOnBtnClick(){
     
        console.log('调用了');
        stopOutput();
        oha_timer.stop();
   }

   
    
/*timmer*/
var _pt=-1;
var Example2 = new (function() {
    var $countdown,
        $form, // Form used to change the countdown time
        incrementTime = 70,
        currentTime = $(".timer").text()*6000,
		stoptime= $(".timer").text()*6000,
        updateTimer = function() {
            $countdown.html(formatTime(currentTime));
            if (currentTime == 0) {
                Example2.Timer.stop();
            	if(_pt>0) {
//            		$('.subtitle').text(_pt);
                	timerComplete();
           		}
            	_pt=-1;
//                timerComplete();
				var newtime= $(".resettimer").text();
	            var resettimer=newtime*6000;
	            Example2.resetCountdown(resettimer);
	            $("#powctrlbtn").hide();
				$(".powerbtn.active").hide();
	            $(".powerbtn.noactive").show();
				$("#timelimit.modebtn").removeClass("working");
                return;
            }
            else if((currentTime < 50) && (currentTime )) {
            	_pt=currentTime;
            }else if(currentTime==stoptime){Example2.Timer.stop();}
            currentTime -= incrementTime / 10;
            if (currentTime < 0) currentTime = 0;
        },
        timerComplete = function() {
//            timeup();
            stopOutput();
       		sendOhaCmd(["sound"]);

        },
        init = function() {
            $countdown = $('#timerAndIcon .timerShow');
            //Example2.Timer = $.timer(updateTimer, incrementTime, true);
			
            $form = $('#example2form');
            $form.bind('submit', function() {
                Example2.resetCountdown();
                return false;
            });
        };
    this.resetCountdown = function(n) {
		
        var newTime = parseInt(n);
        if (newTime > 0) {currentTime = newTime;}
        time_stop()
    };
	
    $(init);
});


  function setTimeBtn(num) {
  	$(".timerItem").each(function(i){
        $(this).removeClass("selected");
  		if(num==i) {
            $(this).addClass("selected");
			$(".timerSelDiv").attr("value", $(this).text());
			var newtime= $(this).find("span.time").text();
            oha_timer.setTime(newtime*60)
			oha_api.cookieWrite(cookieOptions.timerSet.key, newtime*60)
            if(isStarted) {
              var isAndroid=(oha_api.getPlateForm()==oha_api.PlateFormOpt.Android);
              var isApple=(oha_api.getPlateForm()==oha_api.PlateFormOpt.Apple);
              var appVer=oha_api.getAppVer();
              if((isAndroid && (appVer>251)) || isApple &&(appVer>470)) {
                  oha_api.runOhaCmd("omass://start_directNew_"+oha_timer.getRemainTime());
              }
            }
            
//             oha_api.cookieWrite('timer',i+"");
  		}
  	});
  }

  function setModeBtn(num) {
//    		alert("setModeBtn("+num+")");
    $(".modelist").each(function(i){
    	if(num==i) {
//    		alert($(this).text());
			$("#mode.modebtn span").text($(this).text());
			newmode= i;
			setPattern(i);
    	}
    })
  }

function normalUi(){
	console.log("normalUi()")
	modelType=modelTypeDef.Normal;
	$("#patSelBlk>g:gt(3)").show();
	$("#itemUiStyle").show();
	$("#patSel1,#patSel2").attr("transform", "");
	$("#patSel3,#patSel4").attr("transform", "");
}

function faceUi(){
	console.log("faceUi()")
	modelType=modelTypeDef.Face;
	$("#patSelBlk>g:gt(3)").hide();
	$("#itemUiStyle").hide();
	$("#patSel1,#patSel2").attr("transform", "translate(0, 58.5)");
	$("#patSel3,#patSel4").attr("transform", "translate(385, -61.5)");
}

function cookieInit() {
    console.log("cookieInit start");
	modelType=parseInt(oha_api.cookieRead(cookieOptions.modeType.key, cookieOptions.modeType.default));
	if(modelType==modelTypeDef.Face) {
		faceUi();
	}
    var _uiMode=oha_api.cookieRead(cookieOptions.uiMode.key, cookieOptions.uiMode.default);
//    oha_api.showToast("cookieInit");
    switch(_uiMode) {
        case cookieOptions.uiMode.options.barView:
            show_tag();
            break;
        case cookieOptions.uiMode.options.normalView:
            show_normal();
            break;
                  }
	var isReadOnly=(oha_api.cookieRead(cookieOptions.readOnly.key, cookieOptions.readOnly.default)==cookieOptions.readOnly.options.readonly);
	if(isReadOnly) {
		$("#oha_update").addClass("itemHide");
	}
    var uiSize=oha_api.cookieRead(cookieOptions.uiSize.key, cookieOptions.uiSize.default);
    switch(uiSize) {
        case cookieOptions.uiSize.options.normal:
            $("#itemUiSize, .main").removeClass("enlarge");
//            $("#itemUiSize .setItemMsg>h3").text("Enlarged UI Size")
            $("#itemUiSize .setItemMsg>h3").text(getLocaleStr(MsgString.UiSizeMenu.normal));
            break;
        case cookieOptions.uiSize.options.enlarge:
            $("#itemUiSize, .main").addClass("enlarge");
//            $("#itemUiSize .setItemMsg>h3").text("Normal UI Size")
            $("#itemUiSize .setItemMsg>h3").text(getLocaleStr(MsgString.UiSizeMenu.large))
            break;
                  }
    var _title=oha_api.cookieRead(cookieOptions.uiTitle.key, cookieOptions.uiTitle.default);
    if(_title!=cookieOptions.uiTitle.default) {
        $("text#oha_title_2_").text(_title);
    }
    
	if(oha_api.getPlateForm()==oha_api.PlateFormOpt.Apple) {
		if(oha_api.cookieRead(cookieOptions.iOSDevType.key, cookieOptions.iOSDevType.default)==cookieOptions.iOSDevType.options.old) {
			$("#itemIosPat").removeClass("itemHide");
			if(oha_api.cookieRead(cookieOptions.iosSpecific.key, cookieOptions.iosSpecific.default)=="true") {
				isMax33Hz=true;
				$("#itemIosPat").addClass("iosPat");
//				$("#itemIosPat .setItemMsg>h3").text("iOS specific pattern");
				$("#itemIosPat .setItemMsg>h3").text(getLocaleStr(MsgString.IosPatMenu.ios));
			}
			else {
				isMax33Hz=false;
				$("#itemIosPat").removeClass("iosPat");
				$("#itemIosPat .setItemMsg>h3").text(getLocaleStr(MsgString.IosPatMenu.normal));
			}
		}
		else {
			isMax33Hz=false;
			$("#itemIosPat").addClass("itemHide");
		}
	}
    var _strCookie=oha_api.cookieRead(cookieOptions.strCookie.key, cookieOptions.strCookie.default);
    if(_strCookie==cookieOptions.strCookie.options.save) {
        var _voltage=oha_api.cookieRead(cookieOptions.patVoltage.key, cookieOptions.patVoltage.default);
        for(var i=0;i<8;i++) {
            $("#patSel"+(i+1)).attr("param0",_voltage);
        }
        $("#itemStrCookie").addClass("cookie");
//        $("#itemStrCookie .setItemMsg>h3").text("Keep the voltage setting");
        $("#itemStrCookie .setItemMsg>h3").text(getLocaleStr(MsgString.StrCookieMenu.on));
    }
    else {
		$("#itemStrCookie .setItemMsg>h3").text(getLocaleStr(MsgString.StrCookieMenu.off));
    }
//    var _pat=parseInt(oha_api.cookieRead(cookieOptions.patSelect.key, cookieOptions.patSelect.default));
//    setPattern(_pat)
	var _isSoundX=oha_api.cookieRead(cookieOptions.alarmMode.key, cookieOptions.alarmMode.default);
	if(_isSoundX==cookieOptions.alarmMode.options.On) {
		_isSoundOn=true;
		$("#itemSound").find(".setItemIcon").css("background-image", "url('files/sound_on.png')");
		$("#itemSound").find(".setItemMsg").children(0).text(getLocaleStr(MsgString.SoundMenu.On));
		$("#itemSound").addClass(classDef.alarmMode);
		$("g#xBtnSound line").hide();
	}
	else {
		_isSoundOn=false;
		$("#itemSound").find(".setItemIcon").css("background-image", "url('files/sound_off.png')");
		$("#itemSound").find(".setItemMsg").children(0).text(getLocaleStr(MsgString.SoundMenu.Off));
		$("#itemSound").removeClass(classDef.alarmMode);
		$("g#xBtnSound line").show();
	}
//        var _isPowerSaveX=oha_api.cookieRead('isPowerSave', 'true');
	var _isPowerSaveX=oha_api.cookieRead(cookieOptions.powerSaveMode.key, cookieOptions.powerSaveMode.default);
//    console.log("oha_api.cookieRead(isPowerSave)="+_isPowerSaveX);
	setPowerMode(_isPowerSaveX==cookieOptions.powerSaveMode.options.On, true);
//	if(_isPowerSaveX==cookieOptions.powerSaveMode.options.On) {
//		_isPowerMode=true;
//		$("#itemPowerMode").find(".setItemIcon").css("background-image", "url('files/light_off.png')");
//		$("#itemPowerMode").find(".setItemMsg").children(0).text(getLocaleStr(MsgString.PowerSaveMenu.On));
//		$("#itemPowerMode").addClass(classDef.powerMode);
//	}
//	else {
//		_isPowerMode=false;
//		$("#itemPowerMode").find(".setItemIcon").css("background-image", "url('files/light_on.png')");
//		$("#itemPowerMode").find(".setItemMsg").children(0).text(getLocaleStr(MsgString.PowerSaveMenu.Off));
//		$("#itemPowerMode").removeClass(classDef.powerMode);
//	}
    for(var i=0;i<8;i++) {
        var _name="deal"+i;
        var _cookieOpt=cookieOptions[_name];
		var _json=cookieNew.read(_cookieOpt.json.key, "");
		if(_json!="") {
			setPatternAttr(i, _json);
		}
//        var _deal=oha_api.cookieRead(_cookieOpt.key, _cookieOpt.default);
//        var _pat=/([0-9]+)[:]([0-9]+)[:]([0-9]+)/;
//        if(_deal==_cookieOpt.default) {
//            if(!_pat.test(_deal)) {
//                _deal=_cookieOpt.default;
//    //            oha_api.cookieWrite(_cookieOpt.key, _cookieOpt.default);
//            }
//        }
        var _paramObj=_cookieOpt.params;          // Parameters
//        var _voltage=oha_api.cookieRead(_paramObj.voltage.key, _paramObj.voltage.default);
//        $("#patSel"+(i+1)).attr("param0",_voltage);
        var _freq=oha_api.cookieRead(_paramObj.freq.key, _paramObj.freq.default);
		if(isMax33Hz) {
			_freq=oha_api.cookieRead(_paramObj.freqIos.key, _paramObj.freqIos.default);
		}
        $("#patSel"+(i+1)).attr("param1",_freq);
        var _width=oha_api.cookieRead(_paramObj.width.key, _paramObj.width.default);
        $("#patSel"+(i+1)).attr("param2",_width);
//        $("#patSel"+(i+1)).attr("param",_deal);
        
//        $("#patSel"+(i+1)).attr("name", oMassConfig[i][oMassJsonKey.patName].default);
        var _locale=oha_api.getLocale();
        var _localePre=oha_api.cookieRead(cookieOptions.locale, '');
        if(_localePre=='' || (_localePre!=_locale)) {
              var _localeX=_locale.localeCode.split("-");
//            console.log("cookieInit locale="+_locale+", localex="+_localeX[0]+","+_localeX[1]);
            var _n='';
            var _default=_cookieOpt.patName.default;
//            console.log("cookieInit cp-1 _deault="+_default);
//              if((_n=oha_api.cookieRead(_cookieOpt.patName.key+":"+_locale, _default))!=_default) {
              if((_n=oha_api.cookieRead(_cookieOpt.patName.key+":"+_locale, _default))!=_default) {
//                   console.log("cookieInit read pattern "+i+", key="+(_cookieOpt.patName.key+":"+_locale)+", name="+_n)
              }
//              else if((_n=oha_api.cookieRead(_cookieOpt.patName.key+":"+_localeX[0], _default))!=_default) {
              else if((_n=oha_api.cookieRead(_cookieOpt.patName.key+":"+_localeX[0], _default))!=_default) {
//                  console.log("cookieInit read pattern "+i+", key="+(_cookieOpt.patName.key+":"+_localeX[0])+", name="+_n)
              }
//              else if((_n=oha_api.cookieRead(_cookieOpt.patName.key+":default", _default))!=_default) {
              else if((_n=oha_api.cookieRead(_cookieOpt.patName.key+":default", _default))!=_default) {
//                  console.log("cookieInit read pattern "+i+", key="+(_cookieOpt.patName.key+":default")+", name="+_n)
              }
//            console.log("cookieInit cp0 _n="+_n);
//            console.log("cookieInit checkNameP cp1, write pat"+i+" with name="+_n);
            $("#patSel"+(i+1)).attr("name", _n);
//            console.log("cookieInit checkName cp1, write pat"+i+" with name="+$("#patSel"+(i+1)).attr("name"));
        }
        else {
            var _patName="";
            var _patNameObj=_cookieOpt.patName;
            var _patName=oha_api.cookieRead(_patNameObj.key, _patNameObj.default);
//            console.log("cookieInit, write pat"+i+" with name="+_n);
            if(_n!='') {
//                console.log("cookieInit checkNameP cp2, write pat"+i+" with name="+_n);
                $("#patSel"+(i+1)).attr("name", _patName);
//                console.log("cookieInit checkName cp2, write pat"+i+" with name="+$("#patSel"+(i+1)).attr("name"));
            }

        }
        oMassConfig[i][oMassJsonKey.patMode]=oha_api.cookieRead(_cookieOpt.patMode.key, _cookieOpt.patMode.default);
       let _control=oha_api.cookieRead(_cookieOpt.controlItem.key, _cookieOpt.controlItem.default);
        var _ctrlArray=_control.split(":");
        if(_ctrlArray[0]=="false") {
            oMassConfig[i][oMassJsonKey.voltageEn]=false;
        }
        if(_ctrlArray[1]=="false") {
            oMassConfig[i][oMassJsonKey.freqEn]=false;
        }
        if(_ctrlArray[2]=="false") {
            oMassConfig[i][oMassJsonKey.widthEn]=false;
        }
//        _pat=oha_api.cookieRead(_cookieOpt.pattern.key, _cookieOpt.pattern.default);
        let _pat=cookieNew.read(_cookieOpt.pattern.key, _cookieOpt.pattern.default);
        if(_pat!=_cookieOpt.pattern.default) {
            oMassConfig[i][oMassJsonKey.patternCmd]=_pat;
        }
    }
    var _t=parseInt(oha_api.cookieRead(cookieOptions.timerSet.key, cookieOptions.timerSet.default));
	console.log("hello, cookieOptions.timerSet.default="+cookieOptions.timerSet.default+", _t="+_t);
    oha_timer.setTime(_t);
    var _alarm=oha_api.cookieRead(cookieOptions.alarmMode.key, cookieOptions.alarmMode.default);
    switch(_alarm) {
        case cookieOptions.alarmMode.options.On:
            setAlarm(true);
            break;
        case cookieOptions.alarmMode.options.Off:
            setAlarm(false);
            break;
                 }
//    var _power=oha_api.cookieRead(cookieOptions.powerSaveMode.key, cookieOptions.powerSaveMode.default);
//	setPowerMode(_power==cookieOptions.powerSaveMode.options.On);
//    powerSaveMode: {
//        key: 'powerSave',
//        default: 'true', 
//        options: {
//            On:'true', 
//            Off:'false', 
//        }, 
//    },    
//    console.log("cookieInit, devStus="+oha_api.cookieRead(cookieOptionsTop.devStus.key, cookieOptionsTop.devStus.default))
//    console.log("cookieInit cp for devPlugIn")
//    if(oha_api.cookieRead(cookieOptionsTop.devStus.key, cookieOptionsTop.devStus.default)==cookieOptionsTop.devStus.options.connected) {
//        console.log("Send DevPlugIn("+oha_api.cookieRead(cookieOptionsTop.devModel.key, cookieOptionsTop.devModel.default)+")")
//        oha_api.DevPlugIn(oha_api.cookieRead(cookieOptionsTop.devModel.key, cookieOptionsTop.devModel.default));
//    }
	oha_api.setUiAlpha(0.8);
    console.log("cookieInit end");
}

function setcookies(){
	console.log("setcookies")
    if(oha_api.cookieRead('testmode', 'no')!=="yes"){
        var _str=oha_api.cookieRead('strength', "1");
        $("#status div,#xStrOmass text").text(_str);
//        console.log("_str="+_str);
       switch(_str){
          case "1":
          case "2":
          case "3":
          case "4":
              $("#xStrOmass text").attr({"fill":"green"});
              break;
          case "5":
          case "6":
          case "7":
          case "8":
              $("#xStrOmass text").attr({"fill":"yellow"});
              break;
          default:
              $("#xStrOmass text").attr({"fill":"red"});
      }
        num=oha_api.cookieRead('mode', "0");
//        setModeBtn(parseInt(num));
        $("#mode.modebtn span").text($(".modelist").eq(num).text());
		newmode= num;
        if((num=parseInt(oha_api.cookieRead('timer', "-1")))>=0) {
        //		 $("#status div").text($.cookie('timer'));
            console.log("setcookies cp1c(num="+num+")")
            setTimeBtn(num);
        }
//        var _isSoundX=oha_api.cookieRead('isSound', 'true');
        var _isSoundX=oha_api.cookieRead(cookieOptions.alarmMode.key, cookieOptions.alarmMode.default);
//        console.log("_isSoundX="+_isSoundX);
        if(_isSoundX==cookieOptions.alarmMode.options.On) {
            _isSoundOn=true;
            $("#itemSound").find(".setItemIcon").css("background-image", "url('files/sound_on.png')");
            $("#itemSound").find(".setItemMsg").children(0).text(getLocaleStr(MsgString.SoundMenu.On));
            $("g#xBtnSound line").hide();
        }
        else {
            _isSoundOn=false;
            $("#itemSound").find(".setItemIcon").css("background-image", "url('files/sound_off.png')");
            $("#itemSound").find(".setItemMsg").children(0).text(getLocaleStr(MsgString.SoundMenu.Off));
            $("g#xBtnSound line").show();
        }
//        var _isPowerSaveX=oha_api.cookieRead('isPowerSave', 'true');
        var _isPowerSaveX=oha_api.cookieRead(cookieOptions.powerSaveMode.key, cookieOptions.powerSaveMode.default);
    //    console.log("oha_api.cookieRead(isPowerSave)="+_isPowerSaveX);
        if(_isPowerSaveX==cookieOptions.powerSaveMode.options.On) {
            _isPowerMode=true;
            $("#itemPowerMode").find(".setItemIcon").css("background-image", "url('files/light_off.png')");
            $("#itemPowerMode").find(".setItemMsg").children(0).text(getLocaleStr(MsgString.PowerSaveMenu.On));
        }
        else {
            _isPowerMode=false;
            $("#itemPowerMode").find(".setItemIcon").css("background-image", "url('files/light_on.png')");
            $("#itemPowerMode").find(".setItemMsg").children(0).text(getLocaleStr(MsgString.PowerSaveMenu.Off));
        }
    }
}

	 


	 
//	function setOpMode(n) {
//		oha_api.runOhaCmd('omass://opmode_'+n);
////		alert("setOpMode("+n+")");
//	}
	
	function callNotice() {
		oha_api.runOhaCmd('omass://_notice');
	}
	
	 	
	function stopOutput(){
        console.log('执行暂停');
        console.log(oha_api.DeviceId);
        console.log(oha_api.DeviceId);
    // if(oha_api.getPlateForm()==oha_api.PlateFormOpt.Apple) {
    //     audioPlay(); //播放动画
    // }
		oha_api.runOhaCmd("omass://stop");
        oha_api.runOhaCmd("oha://unlock_"+oha_api.DeviceId);
        if(!isOhaPaused) {
            isStarted=false;
        }
//	  oha_api.runOhaCmd("oha://statusUI_OFF")
		oha_api.setPowerStatus(oha_api.PowerStatus.OFF);

	}

//   function poweroff(){
//	 oha_api.runOhaCmd("omass://poweroff"+"_"+usingtime());
//	 }
	 
  function startOutput() {
    //  sendHeightCmd();
    //  sendFreqCmd();
    //  sendWidthCmd();
    //如果是苹果手机会播放广告
    // if(oha_api.getPlateForm()==oha_api.PlateFormOpt.Apple) {
        // audioPlay();
    // }
      sendPatternCmd();
      var isAndroid=(oha_api.getPlateForm()==oha_api.PlateFormOpt.Android);
      var isApple=(oha_api.getPlateForm()==oha_api.PlateFormOpt.Apple);
      var appVer=oha_api.getAppVer();
      if((isAndroid && (appVer>251)) || isApple &&(appVer>470)) {
        //   oha_api.runOhaCmd("omass://start_slowUpNew_"+oha_timer.getRemainTime());
        oha_api.runOhaCmd("omass://start_slowUpNew_"+3600);
      }
      else {
        // oha_api.runOhaCmd("omass://start_slowUpNew_"+30);
          oha_api.runOhaCmd("omass://start");
      }
      //判断是否开始
      oha_api.runOhaCmd("oha://lock_"+oha_api.DeviceId);
//	  oha_api.runOhaCmd("oha://statusUI_ON")
	  oha_api.setPowerStatus(oha_api.PowerStatus.ON);

      isStarted=true;
      console.log('isStarted',isStarted);
  }
  
  function sendOhaCmd(param) {
  	var _cmdStr="oha://";
  	for(i=0;i<param.length;i++) {
  		if(i!=0) {
  			_cmdStr+="_";
  		}
  		_cmdStr+=cmdTrans(param[i]);
  	}
  	setTimeout(function() {
  		oha_api.runOhaCmd(_cmdStr);
//  	alert("sendOhaCmd("+_cmdStr+")");
  		}, 100);
//  	oha_api.runOhaCmd(_cmdStr);
  }
  
  function cmdTrans(_str) {
		var _tmpStr=_str.replace(/%/g, "%%");
		_tmpStr=_tmpStr.replace(/_/g, "%_");
		return _tmpStr;
  }
  
//  function poweron(){
//	 oha_api.runOhaCmd("omass://poweron"); 
//	 }	 

 function pause(){
	 oha_api.runOhaCmd("omass://pause"); 
	}
	 
 function timeup(){
//	 oha_api.runOhaCmd("omass://timeup"+"_"+usingtime()); 
	 console.log("timeup()")
	stopOutput();
//   	  var newtime= $(".resettimer").text();
//	  var resettimer=newtime*6000;
//	  Example2.resetCountdown(resettimer);
     $("#timelimit.modebtn").removeClass("working");
//     $("g#xTimerMinus, g#xTimerPlus").removeAttr("class");
	 if(!oha_api.isReadOnly()) {
		 $("#oha_update").removeClass("itemHide");
	 }
//     if(oha_api.cookieRead("ReadOnly", "false")=="false") {
//        $("#oha_update").show();
//     }
	  sendOhaCmd(["sound"]);
        $("#itemUiStyle").addClass("clickable");
        $("#itemUiStyle").removeAttr("disabled");
		$("#itemStrCookie").addClass("clickable");
		$("#itemStrCookie").removeAttr("disabled");
		$("#titleIconR").removeClass("inactive");
	  if($("#powerOffBg").css("display")!="none") {
          powerSaveOff();
//	  	$("#powerOffBg").hide();
//          $("pwrSaveBtn").hide();
//	  	setTimeout(function() {
//	  		oha_api.runOhaCmd("oha://lightness_-2");
//	  	}, 300);
	  }
};

 function OHA_Attached(){
	 $(".loadingdot").addClass("active");
	 }
function setAD983xFreg(n) {
	var freg=Math.round((Math.pow(2,28)*n)/AD983xObj.baseF);
	var fregHex=freq.toString(16);
	while(freqHex.length<14) {
		fregHex='0'+fregHex;
	}
	oha_api.runOhaCmd("oha://cw_"+oha_api.DeviceId+"_G:H,"+fregHex.substr(0,2)+":H,"+fregHex.substr(2,2)+":H,"+fregHex.substr(4,2)+":H,"+fregHex.substr(6,2)+":H,"+fregHex.substr(8,2)+":H,"+fregHex.substr(10,2)+":H,"+fregHex.substr(12,2))
}

oha_api.DevPlugIn=function(){
//    isOhaPaused=false;
//    $("#powerOffBg").hide();
//    if(isDevReady) {
//        return;
//    }
	console.log("oha_api.DevPlugIn("+arguments[0]+","+arguments[1]+")")
	var _modelX=arguments[0].split(":");
	var modelTypeP=modelType;
	modelType=modelTypeDef.Normal;
	isMax60V=false;
	let AD983x=false;
	for(i=1;i<_modelX.length;i++) {
		switch(_modelX[1]) {
			case "FACE":
				modelType=modelTypeDef.Face;
//				$("#patSelBlk>g:gt(3)").hide();
				
//				faceUi();
				break;
			case "60V":
				if(oha_api.getPlateForm()!=oha_api.PlateFormOpt.Apple) {
					isMax60V=true;
				}
				break;
			case "AD983x":
				isAD983x=true;
				break;
		}
	}
	var _value=parseInt($("#level").attr("value"));
	var vMax=39;
	if(isMax60V) {
		vMax=11;
	}
	if(isAD983x) {
		$("#main_ctrl>.patBlock").append('<div id="ad983xFreqDiv"><input id="ad983xFreqIn" value="25000000" type="text">Hz</div>');
		$("#patSelBlk>g").attr({disabled:"disabled"});
		$("#patSel1").removeAttr("disabled");
		var _ret=oha_api.runOhaCmd("oha://cr_"+oha_api.DeviceId+"_G:06:00");
		if(_ret!='') {
			var _num=_ret.replace(":","");
			AD983xObj.baseF=parseInt(_ret, 16);
		}
		$("#ad983xFreqIn").change(function(){
//			console.log("Input="+$("#ad983xFreqIn").val());
			var _n=parseInt($("#ad983xFreqIn").val())
			setAD983xFreg(_n);
		});
		$("#ad983xFreqIn").keydown(function(e){
			switch(e.which) {
				case 48:
				case 49:
				case 50:
				case 51:
				case 52:
				case 53:
				case 54:
				case 55:
				case 56:
				case 57:
				case 8:		// Back
				case 13:	// Enter
				case 46:	// Delete
					break;
				default:
					alert("incorrect input("+e.which+"), 0-9 only");
					e.preventDefault();
					break;
			}
			var _n=parseInt($("#ad983xFreqIn").val()+String.fromCharCode(e.which));
			if(_n>100*Math.pow(10,6)) {
				alert("Input number to large! Max:100000000");
				e.preventDefault();
			}
		})
		setPattern(0);
	}
	if(modelType==modelTypeDef.Face) {
		vMax=11;
	}
	if(_value>vMax) {
		setHeight(vMax);
	}
	
	if(modelTypeP !== modelType) {
		switch(modelType) {
			case modelTypeDef.Face:
				faceUi();
				break;
			case modelTypeDef.Normal:
				normalUi();
				break;
		}
	}
	oha_api.cookieWrite(cookieOptions.modeType.key, modelType);
	
    $("#powerUnconnected, #xBtnUnconnectOmass").hide();
    $("#powerBtnNormal").show();

    isDevReady=true;
//	oha_api.runOhaCmd("oha://statusUI_OFF")
	oha_api.setPowerStatus(oha_api.PowerStatus.OFF);

	if(oha_api.isReadOnly()) {
		$("#oha_update").addClass("itemHide");
		oha_api.cookieWrite(cookieOptions.readOnly.key, cookieOptions.readOnly.options.readonly);
	}
	else {
		$("#oha_update").removeClass("itemHide");
		oha_api.cookieWrite(cookieOptions.readOnly.key, cookieOptions.readOnly.options.writable);
	}
	 $("#oha_update").addClass("BtnActive");
	//    setOpMode(1);
    if(oha_api.getPlateForm()==oha_api.PlateFormOpt.Apple) {
		if(oha_api.__verFw.indexOf("OTHEOHA")>=0) {		// Audio Device
			$("#itemIosPat").removeClass("itemHide");
			isMax33Hz=true;
			for(var i=1;i<_modelX.length;i++) {
				if(_modelX[i]=="New") {
					$("#itemIosPat").addClass("itemHide");
					oha_api.cookieWrite(cookieOptions.iOSDevType.key, cookieOptions.iOSDevType.options.new);
					isMax33Hz=false;
				}
			}
		}
		else {
			$("#itemIosPat").addClass("itemHide");
			isMax33Hz=false;
		}
		if(isMax33Hz) {
			if(oha_api.cookieRead(cookieOptions.iosSpecific.key, cookieOptions.iosSpecific.default)=="true") {
//				$("#itemIosPat .setItemMsg>h3").text("iOS specific pattern");
				$("#itemIosPat .setItemMsg>h3").text(getLocaleStr(MsgString.IosPatMenu.ios));
			}
			else {
//				$("#itemIosPat .setItemMsg>h3").text("Normal pattern");
				$("#itemIosPat .setItemMsg>h3").text(getLocaleStr(MsgString.IosPatMenu.normal));
			}
		}
    }
	switch(modelType) {
		case modelTypeDef.Normal:
			for(var i=0;i<8;i++) {
				var _name="deal"+i;
				var _paramObj=cookieOptions[_name].params;
				if(isMax33Hz) {
					var _freq=oha_api.cookieRead(_paramObj.freqIos.key, _paramObj.freqIos.default);
					$("#patSel"+(i+1)).attr("param1",_freq);
				}
				else {
					var _freq=oha_api.cookieRead(_paramObj.freq.key, _paramObj.freq.default);
					$("#patSel"+(i+1)).attr("param1",_freq);
				}
			}
			break;
		case modelTypeDef.Face:
			for(var i=0;i<4;i++) {
				var _name="deal"+i;
				var _paramObj=cookieOptions[_name].params;
				if(isMax33Hz) {
					var _freq=oha_api.cookieRead(_paramObj.freqIos.key, _paramObj.freqIos.default);
					$("#patSel"+(i+1)).attr("param1",_freq);
				}
				else {
					var _freq=oha_api.cookieRead(_paramObj.freq.key, _paramObj.freq.default);
					$("#patSel"+(i+1)).attr("param1",_freq);
				}
			}
			break;
		default:
		
	}

    if(typeof arguments[1]!=="undefined") {
//        var _url=oha_api.cookieRead(cookieOptions.remoteJson.key, cookieOptions.remoteJson.default);
        var _url=cookieNew.read(cookieOptions.remoteJson.key, cookieOptions.remoteJson.default);
        if(_url!=arguments[1]) {
            _url=arguments[1];
//            readPatFromRemoteJsFile(arguments[1]+"/oha_controller_options.js")
//            readJson("oha_controller_options.js", true, _url)
//            .done(function(_jdata){
//                parseConfigJson(_jdata, true, _url);
//            })
//            .fail(function(_msg){
//               console.log("readJson fail!!message="+_msg);
//            });
//            oha_api.cookieWrite(cookieOptions.remoteJson.key, _url);
            cookieNew.write(cookieOptions.remoteJson.key, _url);
			oha_api.cookieRemove(cookieOptions.remoteJsonOK.key);
        }
    }
    readJsonAll();
//    $("#powerOffBg").hide();
    if(oha_timer!=null) {
        oha_timer.stop();
    }
//        $("#itemIosPat").removeClass("itemHide");
    oha_api.cookieWrite(cookieOptionsTop.devStus.key, cookieOptionsTop.devStus.options.connected);
    oha_api.cookieWrite(cookieOptionsTop.devModel.key, arguments[0]);
	var _stus =oha_api.getConnectStatus();
	if(_stus!=null) {
		var _stusList=_stus.split(":");
		var _mode=_stusList[0];
		var _pat=parseInt(_stusList[1]);
		var _baseV=parseInt(_stusList[2]);
		var _remainT=parseInt(_stusList[3]);
		if((_mode=="RUN") || (_mode=="PAUSE")) {
			setPattern(_pat+1);
			setHeight(Math.round(_baseV/5)-1);
			oha_timer.setRemainT(_remainT);
			if(_mode=="RUN") {
				powerOffBtnClick(true);
			}
		}
	}
}
 
	 
oha_api.DevPlugOut=function() {
    isOhaPaused=false;
	isStarted=false;
    $("#powerOffBg").hide();
    $("#powerUnconnected, #xBtnUnconnectOmass").show();
    $("#powerBtnNormal, #xPowerOffOmass, #xPowerOnOmass").hide();
    $("#powerLockNormal, #xPowerLockOmass").hide();
    isDevReady=false;
    $("#oha_update").removeClass("BtnActive");
 	if(oha_api.isReadOnly()) {
		$("#oha_update").addClass("itemHide");
	}
	else {
		$("#oha_update").removeClass("itemHide");
	}
   oha_timer.stop();
    oha_api.cookieWrite(cookieOptionsTop.devStus.key, cookieOptionsTop.devStus.options.unconnected);
    $("#itemUiStyle").removeAttr("disabled");
    powerSaveOff();
	if(isAD983x) {
		$("#patSelBlk>g").removeAttr("disabled");
		$("#ad983xFreqDiv").remove();
		isAD983x=false;
	}
}

oha_api.UiNew=function(_ver) {
    $("#titleIconR").removeClass("itemHide");
	$("#titleIconRq").addClass("itemHide");
}

function OHA_Pause() {
    isOhaPaused=true;
    setPause();
//    if($("#powerBtnNormal").css("display")!="none"){
//        isOhaPaused=true;
//        setPause();
//    }
}

function OHA_Resume() {
    if(isOhaPaused) {
        setResume();
        isOhaPaused=false;
    }
}

function OHA_Lock(_id) {
//    console.log("OHA_Lock("+_id+")")
	if(_id==oha_api.DeviceId) {
      
        $("#oha_update").css({"opacity":0.3});
	}
}

function OHA_Unlock(_id) {
//    console.log("OHA_Unlock("+_id+")")
	if(_id==oha_api.DeviceId) {
        if($("#powerLockNormal").css("display")!="none") {
        }
        $("#oha_update").css({"opacity":1.0});
	}
}

    function setPause() {
        isOhaPaused=true;
        stopOutput();
        oha_timer.pause();
        $("#powerOffBg").show();
        $("#powerSaveMsg").text("Pause");
    }

    function setResume() {
        if(isStarted) {
            startOutput();
            oha_timer.play();
        }
        $("#powerOffBg").hide();

        isOhaPaused=false;
    }
//function OHA_ApiVer(_ver) {
//	ohaVer=_ver;
//}

function OHA_RunErr(_id, _errMsg) {
	if(_id==DeviceId) {
		alert("USB連線中斷, 請檢查您的USB接頭是否接觸不良!");
		OHA_DevOff(_id);
	}
}

function OHA_UI() {
//	$(".main").show();
    show_normal();
}

	 
 function omassbreak(){
	 $(".powerbtn.active").click();
	 }
 
  function omassgo(){
	 $(".powctrlbtn.play").click();
	 }
 
 function timeset(n){
	 $(".timer").text(n);
	 }
 function circle(rad,cname,angle){
	var radius = rad;
	var avd = angle/($(cname).length-1); 
	var ahd = avd*Math.PI/180;
	 $(cname).each(function(i){
            $(this).css({"left":Math.sin((ahd*i))*radius,"top":Math.cos((ahd*i))*radius*(-1)-10});
        });	
	 }

 function usingtime(){
	return Number($(".resettimer").text()*60)-Number($("#timerAndIcon .timerShow").text().substr(0,2)*60)-Number($("#timerAndIcon .timerShow").text().substr(3,2));
	 }	 

function show_normal(){
    $(".main").fadeIn(500);
    $("#bottomBarDiv").hide();
    oha_api.cookieWrite(cookieOptions.uiMode.key, cookieOptions.uiMode.options.normalView);
    oha_api.runOhaCmd("oha://bodySize_full");
}


function show_tag() {
    $(".main").hide();
    $("#bottomBarDiv").fadeIn(500);
    oha_api.cookieWrite(cookieOptions.uiMode.key, cookieOptions.uiMode.options.barView);
    //	$("#oha_tag").show();
    var _isActive=($(".powerbtn.active").css("display")!="none")?"on":"off";

//		document.location.href="oha://apiTag_"+_isActive+"_"+timerInSec;
    var _r=-1;
//	_r=Math.ceil($(window).width()*20/$(window).height());
	_r=Math.ceil(winWidth*20/winHeight);
//    if(window.innerHeight>window.innerWidth) {
//        _r=Math.ceil(window.innerWidth*20/window.innerHeight);
//    }
//    else {
//        _r=Math.ceil(window.innerHeight*20/window.innerWidth);
//    }
    // console.log("oha://bodySize_bottomBar_"+_r);
    // oha_api.runOhaCmd("oha://bodySize_bottomBar_"+_r);
}
	
var powerSaveH=null;
var powerSaveCntr=0;
	function powerSaveOn() {
        oha_api.runOhaCmd("oha://bodySize_full");
		if(powerSaveH!=null) {
			clearInterval(powerSaveH);
			powerSaveH=null;
		}
		powerSaveCntr=5;
		$("#powerOffBg").show();
        $("#powerSaveMsg").html("5 Sec to power saving...<br>Tap to exit");
		powerSaveH=setInterval(function(){
			powerSaveCntr--;
			if(powerSaveCntr==0) {
				clearInterval(powerSaveH);
				powerSaveH=null;
				oha_api.runOhaCmd("oha://lightness_-1");
				$("#powerSaveMsg").text("");
			}
			else {
				$("#powerSaveMsg").html(powerSaveCntr+" Sec to power saving...<br>Tap to exit");
			}
		}, 1000);
//        $("#pwrSaveBtn").hide();
	}
	
	function powerSaveOff() {
        if($("#bottomBarDiv").css("display")!=="none"){
            if($(window).height()>$(window).width()) {
//                var _r=Math.ceil($(window).width()*20/$(window).height());
				var	_r=Math.ceil(winWidth*20/winHeight);
                // oha_api.runOhaCmd("oha://bodySize_bottomBar_"+_r);
            }
        }
		$("#powerOffBg").hide();
		if(powerSaveH!=null) {
			clearInterval(powerSaveH);
			powerSaveH=null;
		}
//        $("#pwrSaveBtn").show();
//        $("#pwrSaveBtn").show();
        $("#powerSaveMsg").text("");
        oha_api.runOhaCmd("oha://lightness_-2");
	}

function audioPlay(){
    console.log("audioPlay")
	var _audio=document.getElementById("aud");
//	_audio.currentTime=0;
	_audio.loop = true;
//	_audio.muted=true;
	_audio.play();
}

function audioStop(_isRePlay){
    console.log("audioStop")
	if(typeof _isRePlay=="undefined") {
		_isRePlay=false;
	}
	var _audio=document.getElementById("aud");
	if(_isRePlay) {
		_audio.load();
	}
	document.getElementById("aud").pause();
}



var newmode=0;	
$(document).ready(function(){
    if(localStorage.getItem('over')){
        console.log('执行结束');
    }
/*+ -按鈕*/ 
   let oha_timer_conf={
        timeup: function(){
            console.log("timeup is run");

			timeup();
        },
		maxTime:60
    }
	if(oha_timer!=null) {
		oha_timer=null;
	}
    oha_timer=$("#timerAndIcon").oha_timer(oha_timer_conf);
    
    $("#itemUiSize,#titleIconR1").click(function(){
        $("#itemUiSize").toggleClass("enlarge");
        if($("#itemUiSize").hasClass("enlarge")) {
            $(".main").addClass("enlarge");
//            $("#itemUiSize .setItemMsg>h3").text("Normal UI Size");
            $("#itemUiSize .setItemMsg>h3").text(getLocaleStr(MsgString.UiSizeMenu.large));
        }
        else {
            $(".main").removeClass("enlarge");
//            $("#itemUiSize .setItemMsg>h3").text("Enlarged UI Size");
            $("#itemUiSize .setItemMsg>h3").text(getLocaleStr(MsgString.UiSizeMenu.normal));
        }
        setUiSize();
    })
    
	$("#titleIconRq").click(function(){
		setUiSize();
		$("#titleIconRq").toggleClass("isShow");
		if($("#titleIconRq").hasClass("isShow")) {
			$(".helpMask").show();
			setHelpOrientation(isLandscape);
		}
		else {
			$(".helpMask").hide();
		}
	});
	
    $("#itemStrCookie").click(function(){
        if($(this).attr("disabled")!=null) {
            return;
        }
        $(this).toggleClass("cookie");
        if($(this).hasClass("cookie")) {
//            $(this).find(".setItemMsg>h3").text("Keep the voltage setting");
            $(this).find(".setItemMsg>h3").text(getLocaleStr(MsgString.StrCookieMenu.on));
            oha_api.cookieWrite(cookieOptions.strCookie.key, 'true');
        }
        else {
//            $(this).find(".setItemMsg>h3").text("Voltage setting by default");
            $(this).find(".setItemMsg>h3").text(getLocaleStr(MsgString.StrCookieMenu.off));
            oha_api.cookieWrite(cookieOptions.strCookie.key, 'false');
            oha_api.cookieRemove(cookieOptions.patVoltage.default);
            setHeight(cookieOptions.patVoltage.default);
        }
    })
    
     $("#itemIosPat").click(function(){
		var i=$("#patSelBlk").attr("value");
        $(this).toggleClass("iosPat");
        if($(this).hasClass("iosPat")) {
//            $(this).find(".setItemMsg>h3").text("iOS specific pattern");
            $(this).find(".setItemMsg>h3").text(getLocaleStr(MsgString.IosPatMenu.ios));
			var _value=oha_api.cookieRead(cookieOptions["deal"+i].params.freqIos.key, cookieOptions["deal"+i].params.freqIos.default);
            $("#FreqCtrlBlk").attr("value", _value);
            isMax33Hz=true;
            if(_value>33) {
                _value=33;

            }
			setFreq(_value);
			oha_api.cookieWrite(cookieOptions.iosSpecific.key, "true");
        }
        else {
			var _value=oha_api.cookieRead(cookieOptions["deal"+i].params.freq.key, cookieOptions["deal"+i].params.freq.default);
            $("#FreqCtrlBlk").attr("value", _value);
         	isMax33Hz=false;
//           $(this).find(".setItemMsg>h3").text("Normal pattern");
           $(this).find(".setItemMsg>h3").text(getLocaleStr(MsgString.IosPatMenu.normal));
			setFreq(_value);
			oha_api.cookieWrite(cookieOptions.iosSpecific.key, "false");
        }
         
		if(isDevReady) {
			sendParam();
		}
         readJsonAll();
//        readJson("oha_controller_options.txt")
//        .done(function(_jdata){
//    //        console.log("window.ready read local oha_controller_options.txt OK");
//            parseConfigJson(_jdata);
//        })
//        .fail(function(_msg){
//            console.log("read local json file fail, message:"+_msg);
//        });
    })
    
   $("#itemUiStyle").click(function(){
//        var _uiStyle=oha_api.cookieRead(cookieOptionsTop.uiType.key, cookieOptionsTop.uiType.default);
//        console.log("itemUiStyle click function cp0, UI Style="+_uiStyle);
        if($(this).attr("disabled")!=null) {
            return;
        }
	   oha_api.cookieRemove(cookieOptionsTop.uiType.key, "./");
       oha_api.cookieWrite(cookieOptionsTop.uiType.key, cookieOptionsTop.uiType.options.old, "/");
//        var _uiStyle=oha_api.cookieRead(cookieOptionsTop.uiType.key, cookieOptionsTop.uiType.default);
//        console.log("itemUiStyle click function cp1, UI Style="+_uiStyle);
        isDevReady=false;
//        document.location.href="../default_old.html";
	   document.location.href="../old/"+oha_api.getHtml();
    })
    
    $("#itemAbout").click(function(){
        oha_api.runOhaCmd("oha://checkNet");        // Only for test
//        console.log("itemAbout is clicked")
    });
 
    $("#patSelBlk>g").each(function(i, element){
        $(this).click(function(){
			if($(this).attr("disabled")!="disabled") {
				setPattern(i);
			}
        });
    });
    
    var longClickPeriod=1000;
    var fastClickIntv=500;
    var longBtnClick_T;
    var incBtnFLong=false;
    var decBtnFLong=false;
    var incBtnWLong=false;
    var decBtnWLong=false;
    var incBtnVLong=false;
    var decBtnVLong=false;
    
    var addBtnLongTouch=false;
    var decBtnLongTouch=false;
    
    function incTime(_ts){
		if(typeof(_ts)=="undefined") {
			_ts=60;
		}
        if($("g#add").attr("class")=="working") {
            return;
        }
        oha_timer.incTime(_ts);
		oha_api.cookieWrite(cookieOptions.timerSet.key, oha_timer.getSetTime());
        if(isStarted) {
          var isAndroid=(oha_api.getPlateForm()==oha_api.PlateFormOpt.Android);
          var isApple=(oha_api.getPlateForm()==oha_api.PlateFormOpt.Apple);
          var appVer=oha_api.getAppVer();
//            console.log("add time");
          if((isAndroid && (appVer>251)) || isApple &&(appVer>470)) {
              oha_api.runOhaCmd("omass://start_directNew_"+oha_timer.getRemainTime());
          }
          else {
              oha_api.runOhaCmd("omass://start");
          }
        }
    }
    
    function incTimeFast() {
        if(addBtnLongTouch) {
			var _curT=new Date().getTime();
			if(_curT-timerBtnPressStart>10000) {
				incTime(3600);
				longBtnClick_T=setTimeout(function(){
					incTimeFast();
				}, 200);
			}
			else {
				incTime();
				longBtnClick_T=setTimeout(function(){
					incTimeFast();
				}, fastClickIntv);
			}
        }
    }

    $("#addtime").click(function(){
        console.log("#add click");
        incTime();
    })
    
    $("#addtime").on("touchstart", function(e) {
		timerBtnPressStart=new Date().getTime();
        console.log("#add touch start");
		
        longBtnClick_T=setTimeout(function(){
            addBtnLongTouch=true;
            incTimeFast();
        }, longClickPeriod);
    });
    
    $("#addtime").on("touchend", function(e) {
       console.log("#add touch end");
       clearTimeout(longBtnClick_T);
        addBtnLongTouch=false;
    });
        
    $("#add").on("touchcancel", function(e) {
       console.log("#add touch cancel");
        clearTimeout(longBtnClick_T);
        addBtnLongTouch=false;
    });
    
    function decTime(){
        oha_timer.decTime();
        console.log(cookieOptions.timerSet.key);
        console.log(oha_timer.getSetTime());
        console.log(oha_api.cookieWrite(cookieOptions.timerSet.key, oha_timer.getSetTime()));
		oha_api.cookieWrite(cookieOptions.timerSet.key, oha_timer.getSetTime());
        if(isStarted) {
          var isAndroid=(oha_api.getPlateForm()==oha_api.PlateFormOpt.Android);
          var isApple=(oha_api.getPlateForm()==oha_api.PlateFormOpt.Apple);
          var appVer=oha_api.getAppVer();
          if((isAndroid && (appVer>251)) || isApple &&(appVer>470)) {
              oha_api.runOhaCmd("omass://start_directNew_"+oha_timer.getRemainTime());
          }
          else {
              oha_api.runOhaCmd("omass://start");
          }

        }
    }
    
    function decTimeFast() {
        if(decBtnLongTouch) {
            decTime();
            setTimeout(function(){
                decTimeFast();
            }, 500);
        }
    }

    $("#reduce").click(function(){
        decTime();
    })
    
    $("#reduce").on("touchstart", function(e) {
        longBtnClick_T=setTimeout(function(){
            decBtnLongTouch=true;
            decTimeFast();
        }, 1000);
    });
    
    $("#reduce").on("touchend", function(e) {
        clearTimeout(longBtnClick_T);
        decBtnLongTouch=false;
    });
        
    $("#reduce").on("touchcancel", function(e) {
        clearTimeout(longBtnClick_T);
        decBtnLongTouch=false;
    });
	
	// newClickF("#powerOffNormal, #xPowerOffOmass", powerOffBtnClick);
	// newClickF("#powerOnNormal, #xPowerOnOmass", powerOnBtnClick);
    
    $("#powerOffNormal").click(function(){
        powerOffBtnClick()
        })
    $("#powerOnNormal").click(function(){
        powerOnBtnClick()
            })
            $("#powerOffNormal2").click(function(){
                powerOffBtnClick()
                })
            $("#powerOnNormal2").click(function(){
                powerOnBtnClick()
                    })        

  $(".powerbtn.active, #xPowerOnOmass").click(function (){
//	  pause();
//	  time_pause()
	  stopOutput();
      oha_timer.stop();
	  $(".powerbtn.active, #xPowerOnOmass").hide();
      $(".powerbtn.noactive, #xPowerOffOmass").show();
//	  $("#powctrlbtn").show();
      $("#timelimit.modebtn").removeClass("working");
//      $("g#xTimerMinus, g#xTimerPlus").removeAttr("class");
//    if(oha_api.cookieRead("ReadOnly", "false")=="false") {
//        $("#oha_update").show();
//     }
	  if(!oha_api.isReadOnly()) {
		  $("#oha_update").removeClass("itemHide");
	  }
//      $("#pwrSaveBtn").hide();
//      if(oha_api.cookieRead("ReadOnly", "false")=="false") {
//        $("#oha_update").show();
//      }
//	  $(".strctrl").removeClass("working");	  
	  //$("#playstus").text("繼續"+"   "+"停止");
  });
    
    $(".pulseCtrl .addBtn").click(function(){
//        console.log("hello");
         var _value=parseInt($(".pulseCtrl.levBlock").attr("value"));
        if(_value<15) {
            _value++;
        }
        setPulse(_value);
    });
    
    $(".pulseCtrl .subBtn").click(function(){
        var _value=parseInt($(".pulseCtrl.levBlock").attr("value"));
        if(_value>0) {
            _value--;
        }
        setPulse(_value);
    });
    
    $("#incBtnF").click(function(){
        incBtnF();
    });
    
    function incBtnF() {
         var _value=parseInt(localStorage.getItem('frequencyindex'));
        _value+=1;
        console.log(_value);
//        if(!$("#itemIosPat").hasClass("itemHide") && $("#itemIosPat").hasClass("iosPat")) {
		var fmax=freqMapTab.length-1;
		if(oMassItemConfig.hasOwnProperty(oMassJsonKey.maxFreq)) {
			fmax=parseInt(oMassItemConfig[oMassJsonKey.maxFreq])
		}
		console.log("value="+_value+", fmax="+fmax);
		if(_value>fmax) {
			_value=fmax;
		}
        //塞入频率
		setFreq(_value);
        if(isMax33Hz) {
            if(_value>33) {
                _value=33;
            }
//            setFreq(_value);
            if(isDevReady) {
                sendParam();
            }

        }
        else {
            if(_value<freqMapTab.length) {
//                setFreq(_value);
                if(isDevReady) {
                    sendParam();
                }
            }
        }
    }
    
    function decBtnF() {
        var _value=parseInt(localStorage.getItem('frequencyindex'));
        _value-=1;
        if(_value>=0) {
			if(oMassItemConfig.hasOwnProperty(oMassJsonKey.maxFreq)) {
				var fmax=parseInt(oMassItemConfig[oMassJsonKey.maxFreq])
				console.log("value="+_value+", fmax="+fmax);
				if(_value>fmax) {
					_value=fmax;
				}
			}
//			console.log("##setFreq cp4")
            setFreq(_value);
            if(isDevReady) {
                sendParam();
            }
        }
    }
    
    $("#decBtnF").click(function(){
        decBtnF();
    });
    
    function incBtnW() {
        var _value=parseInt(localStorage.getItem('waveindex')) 
        _value+=1;
        console.log(_value);
        if(_value<10) {
            setWidth(_value);
            if(isDevReady) {
                sendParam();
            }
        }
    }
    
    $("#incBtnW").click(function(){
        incBtnW();
    });
    
    function decBtnW() {
        var _value=parseInt(localStorage.getItem('waveindex')) 
        _value-=1;
        if(_value>=0) {
            setWidth(_value);
            if(isDevReady) {
                sendParam();
            }
        }
    }
    $("#decBtnW").click(function(){
        decBtnW();
    });
    
    function incBtnH() {
        console.log($("#level").text());
        var _value=parseInt($("#level").text());
 		// var vMax=39;
		// // var vMax=99;
		// if(isMax60V) {
		// 	vMax=11;
		// }
		// if(modelType==modelTypeDef.Face) {
		// 	vMax=11;
		// }
        // if(_value<vMax) {
            console.log('准备塞入强度');
			// _value+=1;
           setHeight(_value);
            if(isDevReady) {
                console.log('增加强度');
                sendParam();
            }
        // }
		// else {
        //     console.log('大于最大强度');
		// 	_value=vMax;
		// 	setHeight(_value);
		// }
    }
	
    $("#incBtnH, #xHPlus").click(function(){
        incBtnH();
    });
    $("#changemade").click(function(){
        console.log('修改模式');
        console.log(parseInt($("#level").text()));
        console.log(isStarted);
        if(isStarted){
            var i = localStorage.getItem('activeindex')
            console.log(i);
            sendPatternCmd()
            updateCmd(i)
        }
      
    });
    
    function decBtnH(){
        var _value=parseInt($("#level").text());
         if(_value>0) {
			 _value-=10;
           setHeight(_value);
            if(isDevReady) {
                sendParam();
            }
        }        
    }
    
    $("#decBtnH, #xHMinus").click(function(){
        decBtnH();
    });
    
    
    var btnClickLong=false;
    function btnLongClickF(_funName){
        _funName();
       if(btnClickLong) {
            setTimeout(function(){
                btnLongClickF(_funName);
            }, fastClickIntv);
        }
    }
    
    $("#incBtnF,#incBtnW,#incBtnH,#xHPlus,#decBtnF,#decBtnW,#decBtnH,#xHMinus").on("touchstart", function(){
        if($(this).is($("#incBtnF"))) {
            console.log("incBtnF is clicked")
            longBtnClick_T=setTimeout(function(){
                btnClickLong=true;
                btnLongClickF(incBtnF);
//                incBtnF_Fast();
            }, longClickPeriod)
        }
        else if($(this).is($("#incBtnW"))) {
            console.log("incBtnW is clicked")
            longBtnClick_T=setTimeout(function(){
                btnClickLong=true;
                btnLongClickF(incBtnW);
//                incBtnW_Fast();
            }, longClickPeriod)
        }
        else if($(this).is($("#incBtnH,#xHPlus"))){
            console.log("incBtnV is clicked")
            longBtnClick_T=setTimeout(function(){
                btnClickLong=true;
                btnLongClickF(incBtnH);
//                incBtnH_Fast();
            }, longClickPeriod)
        }
        else if($(this).is($("#decBtnF"))) {
            console.log("decBtnF is clicked")
            longBtnClick_T=setTimeout(function(){
                btnClickLong=true;
                btnLongClickF(decBtnF);
//                decBtnF_Fast();
            }, longClickPeriod)
        }
        else if($(this).is($("#decBtnW"))) {
            console.log("decBtnW is clicked")
            longBtnClick_T=setTimeout(function(){
                btnClickLong=true;
                 btnLongClickF(decBtnW);
//               decBtnW_Fast();
            }, longClickPeriod)
        }
        else {
            console.log("decBtnH is clicked")
            longBtnClick_T=setTimeout(function(){
                btnClickLong=true;
                btnLongClickF(decBtnH);
//                decBtnH_Fast();
            }, longClickPeriod)
        }
    });
    
    $("#incBtnF,#incBtnW,#incBtnH,#xHPlus,#decBtnF,#decBtnW,#decBtnH,#xHMinus").on("touchend", function(){
//        console.log("panel button touchend is clicked")
        clearTimeout(longBtnClick_T);
        btnClickLong=false;
    });
    
    var btnClickLongCancelH=null;
    $("#incBtnF,#incBtnW,#incBtnH,#xHPlus,#decBtnF,#decBtnW,#decBtnH,#xHMinus").on("touchmove", function(){
        console.log("panel button touchmove is clicked")
        if(btnClickLongCancelH!=null) {
            clearTimeout(btnClickLongCancelH);
            btnClickLongCancelH=null;
        }
        btnClickLongCancelH=setTimeout(function(){
            clearTimeout(longBtnClick_T);
            btnClickLong=false;
        }, 500);
    });
    
    $("#incBtnF,#incBtnW,#incBtnH,#xHPlus,#decBtnF,#decBtnW,#decBtnH,#xHMinus").on("touchcancel", function(){
        console.log("panel button touchcancel is clicked")
        clearTimeout(longBtnClick_T);
        btnClickLong=false;
    });
    
    $(".powctrlbtn.play").click(function(){
//	  poweron();
      setstr($("#status div").text())
	  if(_isPowerMode) {
	  	setTimeout(function() {
	  		powerSaveOn();
//            console.log("lightness-1")
	  		oha_api.runOhaCmd("oha://lightness_-1");
	  	},500);
	  }
	  oha_timer.play();
	  $("#powctrlbtn").hide();
	  $(".powerbtn.active").show();
      $("#timelimit.modebtn").addClass("working");
	  $("#playstus").text("暫停");
	  $(".strctrl").addClass("working");
		$("#oha_update").addClass("itemHide");
//    if(oha_api.cookieRead("ReadOnly", "false")=="false") {
//        $("#oha_update").hide();
//     }

  })
     $(".powctrlbtn.stop").click(function(){
//	  poweroff();
	  stopOutput();
//	  setTimeout('setstr(1)',100)
	  var newtime= $(".resettimer").text();
	  var resettimer=newtime*6000;
	  Example2.resetCountdown(resettimer);
	  $("#powctrlbtn").hide();
	  $(".powerbtn.noactive").show();
          $("#timelimit.modebtn").removeClass("working");
		 if(!oha_api.isReadOnly()) {
			 $("#oha_update").removeClass("itemHide");
		 }
//      if(oha_api.cookieRead("ReadOnly", "false")=="false") {
//        $("#oha_update").show();
//      }
//		  $(".strctrl").removeClass("working");
	    
	  //$("#playstus").text("啟動");
  })
/*mode按鈕*/
  $("#mode.modebtn").click(function(){
	$(this).toggleClass("active"); 
	$(".blackbg").fadeToggle();
	/*$(".modelist").toggleClass("active");*/
	if($(this).hasClass("active")){
	circle($(window).width()/5,"#mode.modebtn.active .modelist",180);
	}else{
	circle(0,"#mode.modebtn.modelist",180);
	} 
  }) 
  $("#timelimit.modebtn").click(function(){
if(!$(this).hasClass("working")){  
	$(this).toggleClass("active"); 
	$(".blackbg").fadeToggle(); 
	/*$(".modelist").toggleClass("active");*/
	if($(this).hasClass("active")){
	circle($(window).width()/4,"#timelimit.modebtn.active .timelist",-180);
	}else{
	circle(0,"#timelimit.modebtn .timelist",-180);
	} 
	}
  }) 
    
    $("#clock_icon").click(function(){
        $(".timerSelDiv").show();
		var _ss=oha_timer.getSetTime();
		var _sm=_ss/60;
//		console.log("_ss="+_ss+", _sm="+_sm);
		$(".timerSelDiv .timerItem").each(function(i) {
			x=$(this).find(".time").text();
//			console.log("i="+i+", $(this).text()="+x);
			if(_sm==parseInt(x)) {
				$(this).addClass("selected")
			}
			else {
				$(this).removeClass("selected");
			}
		});
		$("#menuBg").show();
    });
    
  $(".blackbg").click(function(){
	$("#timelimit.modebtn.active,#mode.modebtn.active").click();
  
  })
//  $(".modelist").each(function(i){
//	  $(this).addClass("modelist"+i);
//	  $(this).click(function(){
//	  	setModeBtn(i);
//		oha_api.cookieWrite('mode',i+""); 
////		$("#mode.modebtn span").text($(this).text());
////		newmode= i;
////		$.cookie('mode',i, { expires: 365 }); 
////		setPattern(i);
//	  })
//  })
//  
//  $(".timelist").each(function(i){
//	  $(this).addClass("timelist"+i);
//	 
//	  $(this).click(function(){
//	   
//	   	setTimeBtn(i);
////          console.log("i="+i)
////		oha_api.cookieWrite('timer',i+""); 
//    	//time_stop(i)
//		
//	  })
//  })
    
    $(".timerSelDiv .timerItem").each(function(i){
        $(this).click(function(){
            setTimeBtn(i);
//            oha_api.cookieWrite('timer',i+"");
            $(this).parent().hide();
			$("#menuBg").hide();
        });
    })
  
  $('#settings').click(function() {
//  	alert("Hello");
  	oha_api.runOhaCmd('omass://_settings');
  });
  
//TEST模式進入
var timeout ;  
   
$("#detect").mousedown(function() {  
    timeout = setTimeout(function() {  
		if(oha_api.cookieRead('testmode', "no")!="yes"){
	        alert("啟動TEST MODE");
			oha_api.cookieWrite('testmode', 'yes'); 
		}else{
			 alert("關閉TEST MODE"); 
			oha_api.cookieWrite('testmode', 'no'); 
		}
    }, 3000);  
});  
   
$("#detect").mouseup(function() {  
    clearTimeout(timeout);  
   // $("#mydiv").text("out");  
});  
  
$("#detect").mouseout(function() {  
    clearTimeout(timeout);  
   // $("#mydiv").text("out");  
});


$("#oha_min").click(function(){
	show_tag();
})

$("#menuBg").click(function(){
    $(".setMenuClass, .timerSelDiv").hide();
    if($("#bottomBarDiv").css("display")!=="none") {
        if($(window).width()<$(window).height()) {
//            var _r=Math.ceil($(window).width()*20/$(window).height());
			var	_r=Math.ceil(winWidth*20/winHeight);
            // oha_api.runOhaCmd("oha://bodySize_bottomBar_"+_r);
        }
    }
})

$("#titleDiv #titleIconL, #xBtnSettings").click(function() {
    if($("#bottomBarDiv").css("display")!=="none") {
//        console.log("bodySize change full")
        oha_api.runOhaCmd("oha://bodySize_full");
    }
	$(".setMenuClass").show();
//	$("#setMenuTitleText").css({'font-size':sf*0.04+'px'});
//    console.log("oha_api.cookieRead(isSound)="+_isSoundX);
});
    

$("#setMenuEnd").click(function() {
	$(".setMenuClass").hide();
    if($("#bottomBarDiv").css("display")!=="none") {
        if($(window).width()<$(window).height()) {
//            var _r=Math.ceil($(window).width()*20/$(window).height());
			var	_r=Math.ceil(winWidth*20/winHeight);
            // oha_api.runOhaCmd("oha://bodySize_bottomBar_"+_r);
        }
    }
});

$("#itemSound, #button_sound, g#xBtnSound").click(function() {
    $("#itemSound").toggleClass(classDef.alarmMode);
    var _isAlarmOn=$("#itemSound").hasClass(classDef.alarmMode);
    setAlarm(_isAlarmOn);
//	if(_isAlarmOn) {
//		$("#itemSound").find(".setItemIcon").css("background-image", "url('files/sound_on.png')");
//        $("g#xBtnSound line").hide();
//        $("#button_sound").css({"opacity":"1.0"});
//		$("#itemSound").find(".setItemMsg").children(0).text("Alert sound on");
//		oha_api.runOhaCmd("oha://loudness_-2");
////        console.log("cookieWrite(isSound, true)");
//        oha_api.cookieWrite('isSound', "true");
//	}
//	else {
//		$("#itemSound").find(".setItemIcon").css("background-image", "url('files/sound_off.png')");
////		$(this).find(".setItemMsg h4").text("Alert sound off");
//        $("#itemSound").find(".setItemMsg").children(0).text("Alert sound off");
//        $("#button_sound").css({"opacity":"0.5"});
//        $("g#xBtnSound line").show();
//		oha_api.runOhaCmd("oha://loudness_-1");
////        console.log("cookieWrite(isSound, false)");
//        oha_api.cookieWrite('isSound', "false");
//	}
});
    
$("#itemPowerMode").click(function() {
    $("#itemPowerMode").toggleClass(classDef.powerMode);
	var _isPowerOn=$("#itemPowerMode").hasClass(classDef.powerMode);
    setPowerMode(_isPowerOn);
});
    

var _isPowerRate=90;
$("#itemPowerSet").click(function() {
	if(_isPowerRate==90) {
		_isPowerRate=50;
	}
	else {
		_isPowerRate=90;
	}
	if(_isPowerRate==90) {
		$(this).find(".setItemIcon").css("background-image", "url('files/light90.png')");
		$(this).find(".setItemMsg").text(getLocaleStr(MsgString.PowerSetMenu.p90));
		oha_api.runOhaCmd("oha://lightness_90");
	}
	else {
		$(this).find(".setItemIcon").css("background-image", "url('files/light50.png')");
		$(this).find(".setItemMsg h3").text(getLocaleStr(MsgString.PowerSetMenu.p50));
		oha_api.runOhaCmd("oha://lightness_50");
	}
});

    $("g#xBtnEnlarge").click(function(){
        oha_api.runOhaCmd("oha://bodySize_full");
        show_normal();
        if(forDebug) {
        setTimeout(function(){
            var _title=$("text#oha_title_2_").text();
            $("text#oha_title_2_").text(_title);
        }, 100);
        }
    });

    
$("#powerOffBg").click(function() {
	console.log("powerOffBg is clicked")
    if(isOhaPaused) {
        setResume();
    }
//    if("#bottomBarDiv").css("display")!=="none"){
//            if(window.innerHeight>window.innerWidth) {
//                var _r=Math.ceil(window.innerWidth*20/window.innerHeight);
//                oha_api.runOhaCmd("oha://bodySize_bottomBar_"+_r);
//            }
//    }
//	$("#powerOffBg").hide();
    powerSaveOff();

});

//	var _powerOffBg=document.getElementById("powerOffBg");
//	_powerOffBg.addEventListener("touchstart", function(e) {
//		console.log("powerOffBg touchstart")
//	}, false);
//	_powerOffBg.addEventListener("touchend", function(e) {
//		console.log("powerOffBg touchend")
//	}, false);

    $("#titleIconR").click(function(){
        $("#titleIconR").addClass("itemHide");
        $("#titleIconRq").removeClass("itemHide");
		stopOutput();
        oha_api.runOhaCmd("oha://updateUi");
    })
    
    $("#powerSaveBtn, #xPowerSaveBtn").click(function() {
//        oha_api.runOhaCmd("oha://bodySize_full");
//        $("#powerOffBg").show();
        if($("#itemPowerMode").hasClass(classDef.powerMode)) {
            powerSaveOn();
            if($(this).hasClass("title_icon")) {    // #pwrSaveBtn
                $(this).hide();
            }
    //        console.log("lightness-1")
//            oha_api.runOhaCmd("oha://lightness_-1");
        }
    });

	$("#oha_update").click(function(){
        if($(this).css("opacity")!=1.0) {
            return;
        }
         console.log("#oha_update is clicked")
       if($(this).hasClass('BtnActive')) {
//            console.log("btn is active");
			oha_api.runOhaCmd("oha://updateUrl");
//            document.location.href="oha://updateUrl";
        }
	})
    
    $("#re-connect").click(function(){
        $(this).css("background-image","url(files/re-connect1.png)");
        setTimeout(function(){
            $("#re-connect").css("background-image","url(files/re-connect.png)");
            document.location.href="oha://reConnect";
        }, 200);
    });
	
	var btn0Down=false;
	var btn1Down=false;
	var playTimeout=null;
	var releaseTimeout=null;
	var btn01Down=false;
	const appCmdPat=/^DEV:BTN:([01]):([DU])$/;
	oha_api.registerAppCmd(function(_cmd) {
		console.log("oha_api.registerAppCmd, get("+_cmd+")");
		if(!appCmdPat.test(_cmd)) {
			return;
		}
		var _idx=parseInt(RegExp.$1);
		var _evt=RegExp.$2;
		console.log("button"+_idx+" with event="+_evt+" is launched!!");
		switch(_idx) {
			case 1:		// Decrease Strength
				if(_evt=="D") {
					btn1Down=true;
					if(btn01Down) {
						return;
					}
					if(btn0Down) {
						$("#incBtnH path").attr({fill:"#FFFFFF"});
						$("#incBtnH circle").attr({stroke:"#FFFFFF"});
						if(playTimeout==null) {
							playTimeout=setTimeout(function(){
								$("#powerBtnNormal,#xBtnPowerOmass").css({opacity:"0.5"});
								btn01Down=true;
								playTimeout=null;
								if(isStarted) {
									powerOnBtnClick();
								}
								else {
									powerOffBtnClick();
								}
								$("#powerBtnNormal,#xBtnPowerOmass").css({opacity:"1.0"});
							}, 1000);
						}
					}
					else {
						$("#decBtnH path").attr({fill:"rgba(256, 256, 256, 0.5)"});
						$("#decBtnH circle").attr({stroke:"rgba(256, 256, 256, 0.5)"});
					}
				}
				else if(_evt=="U") {
					btn1Down=false;
					if(playTimeout!=null) {
						clearTimeout(playTimeout);
						playTimeout=null;
					}
					if(btn01Down) {
						if(!btn0Down) {
							btn01Down=false;
						}
					}
					else {
						$("#decBtnH path").attr({fill:"#FFFFFF"});
						$("#decBtnH circle").attr({stroke:"#FFFFFF"});
						decBtnH();
					}
//					btn01Down=false;
				}
				break;
			case 0:		// Increase Strength
				if(_evt=="D") {
					btn0Down=true;
					if(btn01Down) {
						return;
					}
					if(btn1Down) {
						$("#decBtnH path").attr({fill:"#FFFFFF"});
						$("#decBtnH circle").attr({stroke:"#FFFFFF"});
						if(playTimeout==null) {
							playTimeout=setTimeout(function(){
								$("#powerBtnNormal,#xBtnPowerOmass").css({opacity:"0.5"});
								btn01Down=true;
								playTimeout=null;
								if(isStarted) {
									powerOnBtnClick();
								}
								else {
									powerOffBtnClick();
								}
								$("#powerBtnNormal,#xBtnPowerOmass").css({opacity:"1.0"});
							}, 1000);
						}
					}
					else {
						$("#incBtnH path").attr({fill:"rgba(256, 256, 256, 0.5)"});
						$("#incBtnH circle").attr({stroke:"rgba(256, 256, 256, 0.5)"});
					}
				}
				else if(_evt=="U") {
					btn0Down=false;
					if(playTimeout!=null) {
						clearTimeout(playTimeout);
						playTimeout=null;
					}
					if(btn01Down) {
						if(!btn1Down) {
							btn01Down=false;
						}
					}
					else {
						$("#incBtnH path").attr({fill:"#FFFFFF"});
						$("#incBtnH circle").attr({stroke:"#FFFFFF"});
						incBtnH();
					}
//					btn01Down=false;
				}
				break;
		}
	});

})

 // detecting() 偵測中
 // detectok() 偵測完畢 顯示POWER
 // plugout()  回到最初畫面
 // timeset(n) 輸入時間
 // circle(rad,cname,angle) 半徑、class name、圓周角度
